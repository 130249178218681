import { useContext } from "react";
import Chart from "react-apexcharts";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "../../../providers/ThemeProvider";


import moment from "moment";
import { FONT_WEIGHT } from "ag-charts-community/dist/esm/es6/module-support";

const MyLoader = ({ themeMode }) => (
  <ContentLoader viewBox="0 0 100 25"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="1" rx="2" ry="2" width="100" height="24" />
  </ContentLoader>
);

function BiweeklyLineChart({
  admitlinechartat,
  admitlinechartseries,
  isLoader,
  color,
  title,
  data,
  minData,
  maxMaxData,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);


  var options = {
    chart: {
    //  height: 350,
    //  type: "line",
      stacked: false,

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming behavior
      },
    },

    dataLabels: {
      enabled: true,
      hideOverflowingLabels: true,
    
      offsetY: -5,
      style: {
        fontSize: "12px",
        FONT_WEIGHT: "600",
        colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
        padding: {
          top: 0,
        },
      },
      background: {
        enabled: false,
       
      },
     
    },
    colors: ["#c4b07b", "#fcb714", "#2878bd"],
 
    stroke: {
      width: [2, 2]
    },
    
    plotOptions: {
      bar: {
        columnWidth: "40%"
      }
    },
    xaxis: {
        categories: ["Oct 2022", "Jan 2023", "Apr 2023", "Jul 2023", "Oct 2023", "Jan 2024", "Apr 2024", "Aug 2024", "Oct 2023", "Jan 2024", "Apr 2024", "Aug 2024"],
      title: {
        show: false,
        
      },
      labels: {
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
      },
      tickPlacement: 'between',
    },
    yaxis: 
   
      [
        {
        title: {
          text: 'Total Dollers',
          style: {
            color: themeMode.theme === "DARK" ? "white" : "black",
          },
        },
      
        labels: {
         style: {
           colors: themeMode.theme === "DARK" ? "white" : "black",
         },
    
       },
      
      }, {
        opposite: true,
        title: {
          text: 'Worked FTEs',
          style: {
            color: themeMode.theme === "DARK" ? "white" : "black",
          },
          
        },
        labels: {
          style: {
            colors: themeMode.theme === "DARK" ? "white" : "black",
          },
     
        },
      }],
      
      
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
       
      },
    legend: {
        labels: {
            colors: themeMode.theme === "DARK" ? "white" : "black",
            useSeriesColors: false
        },
      horizontalAlign: "center",
      position: "top",
      offsetY: 0,
      offsetX: 0
    },

    // annotations: {
    //     yaxis: [
    //       {
    //         y: 2.3,
    //         strokeDashArray: 8,
    //         borderWidth: 1,
    //         borderColor: themeMode.theme === "DARK" ? "#f1b017" : "#f1b017",
          
    //         label: {
    //           borderColor: "#f1b017",
    //           textAnchor: "start",
    //           position: "left",
    //           style: {
    //             color: "#000",
    //             background: "#f1b017"
    //           },
    //           text: "Avg. Target Worked FTEs"
    //         }
    //       },
    //       {
    //         y: 3.5,
    //         strokeDashArray: 8,
    //         borderWidth: 1,
    //         borderColor: themeMode.theme === "DARK" ? "#6ca9d6" : "#6ca9d6",
          
    //         label: {
    //           borderColor: "#6ca9d6",
    //           textAnchor: "start",
    //           position: "left",
    //           style: {
    //             color: "#000",
    //             background: "#6ca9d6"
    //           },
    //           text: "Avg. Actual Worked FTEs"
    //         }
    //       },
          
    //     ],
    // },

  };
  
  return (
    <div>
      {!isLoader ? (
        <div className="bg-white pb-0 ps-2 pe-2">
          {/* <div class="f-14 text-center mb-minus-10 font-w-500" style={{ color: "themeMode.theme === 'DARK' ? 'white' : 'black'," }}>
            {title}
          </div> */}
          <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "left", alignItems: "center" }}>
            
            <div style={{ width: "100%" }}>
              <Chart
                options={options}
                series={
                  [
                    {
                      name: 'Dollar Variance',
                      type: 'column',
                      data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
                    }, {
                      name: 'Target Worked FTEs',
                      type: 'line',
                      data: [23, 42, 35, 527, 243, 22, 17, 31, 522, 722, 12, 16]
                    },
                    {
                      name: 'Actual Worked FTEs',
                      type: 'line',
                      data: [143, 112, 250, 220, 100, 152, 237, 351, 732, 442, 232, 146]
                    }
                  ]
                }
           
               // type="line"
           
               height={300}
              />
            </div>
          </div>
        </div>
      ) : (
          <MyLoader themeMode={themeMode} />
      )}
    </div>
  );
}

export default BiweeklyLineChart;
