import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { API_CALL_URL_PRIVATE } from "../../../constants/compensate";
import axios from "../../../axios";
import Moment from "moment";
import "./index.css";
import { isEmpty } from "lodash";
import LeftIcon from "../../../assets/img/left.png";
import RightIcon from "../../../assets/img/right.png";

import { ThemeContext } from "../../../providers/ThemeProvider";
import { THEME_TYPE } from "../../../constants/theme";

import ErrorModal from "../../../components/shared/SuccessModal/ErrorModal";

import { SocketContext } from "../../../socket";
import SHIFT_FORM from "../../../constants/shiftForm";
import { formatDecimalNumber } from "../../../utils/utils";
import {
  decryptedFunction,
  dataEnCrypt,
  formatDashDate,
} from "../../../utils/helper";
const init = {
  acuity: null,
  wit: null,
  actual_admit: null,
  ant_admit: null,
  charge: null,
  cna: null,
  comment: null,
  submitted_by_text: null,
  cost_center_number: null,
  currentShiftNumber: null,
  one_five: null,
  one_four: null,
  one_six: null,
  id: null,
  nurses: null,
  one_one: null,
  two_one: null,
  orientation: null,
  res_brk_rn: null,
  shift_time: null,
  sitter: null,
  telemetry: null,
  one_three: null,
  shift_date: null,
  total_patients: null,
  total_patients_two: null,
  total_patients_three: null,
  one_two: null,
  unit_sec: null,
  vaso_active_drips: null,
  isolation_patients: null,
  of_isolation: null,
  of_rrs_codes: null,
  of_video_monitors: null,
  techs_cna: null,
  psa_si: null,
  psa_medical: null,
  blt: null,
  other: null,
  soc_worker_ft: null,
  soc_worker_pt: null,
  occ_therapist: null,
  pp_rn: null,
  l_and_d_rn: null,
  nicu_peds_rn: null,
  triage_rn: null,
  observation_patients: null,
  inpatient_boarders: null,
  pt_type1_0_20: null,
  pt_type2_21_28: null,
  pt_type3_29_68: null,
  labor_patients_recovery: null,
  nicu_cpap: null,
  nicu_feeder_growers: null,
  post_op_pp_one_two: null,
  stable_pp_one_three: null,
  of_patients_w_video_monitor_last_six_hours: null,
  of_patients_in_seclusion_last_six_hours: null,
  of_patients_in_restraints_last_six_hours: null,
  of_icu_patients_waiting_in_er: null,
  of_anticipated_transfers_in_next_four_hours: null,
  of_anticipated_transfers_out_next_four_hours: null,
  of_rns_wa_wit_higher_than_shift_standard: null,
  of_pcu_pts_waiting_in_er: null,
  of_c_sections_since_last_time_stamp: null,
  of_inductions_since_last_time_stamp: null,
  of_births_since_last_time_stamp: null,
  of_triage_patients_since_last_time_stamp: null,
  of_pp_hemorrhage_ob_emergency: null,
  of_resuscitations: null,
  of_op_dialysis_patients: null,
  l_and_d_rn_variance: null,
  nicu_variance: null,
  pp_rn_variance: null,
  custom_rn_variance: null,
  lead_tech: null,
  clinical_coordinator: null,
  front_desk: null,
  ivs: null,
  ed_reg: null,
  seven_ave_reg: null,
  rt: null,
  support: null,
  scrub_tech: null,
  rn_preceptor: null,
  rn_board_runner: null,
  scrub_tech_preceptor: null,
  scrub_tech_board_runner: null,
  rnfa_stfa: null,
  rcu_rn: null,
  total_uos: null,
  of_cardiac_procedures: null,
  front_desk_reg: null,
  ivs_encounters_processed: null,
  pts_scheduled: null,
  new_referral: null,
  work_queue_calls: null,
  procedures: null,
  echo: null,
  dobutamine_stress_echo: null,
  stress_echo: null,
  transesopageal_echo: null,
  gi: null,
  ercp: null,
  bronchoscopy: null,
  planned_procedures: null,
  of_cases: null,
  items: null,
  unprocessed_trays: null,
  cases_scheduled_today: null,
  cases_picked: null,
  actual_minutes: null,
  planned_minutes: null,
  iol_pain_local_cases: null,
  mac_cases: null,
  pacu_cases: null,
  cath_lab_cases: null,
  level_1_visits: null,
  level_2_visits: null,
  level_3_visits: null,
  level_4_visits: null,
  level_5_visits: null,
  of_roomed_er_patients: null,
  psych_pts_awaiting_placement: null,
  capped_pts_awaiting_hospitalist_assignment: null,
  admits_awaiting_bed_assignment_boarders: null,
  pts_in_waiting_room: null,
  seven_ave_reg_patient: null,
  ed_reg_patient: null,
  cx_no_shows_w_i_24_hrs: null,
  downtime_hrs: null,
  hrs_non_cath_proc: null,
  of_proc_cx_no_shows_w_i_24_hrs: null,
  hrs_equip_down: null,
  on_call_hrs_paid: null,
  times_called_in: null,
  of_hrs_in_surgery: null,
  active_work_queue: null,
  total_work_queue: null,
  uncontacted_referral_grater_7_days: null,
  surgery_hrs: null,
  fluoro_hrs: null,
  ip_same_day_add_ons: null,
  actual_rn_worked_hrs: null,
  uae_echos: null,
  providers_out: null,
  cancellations_within_24_hrs: null,
  ip_add_ons: null,
  dac_hrs_spent: null,
  no_shows: null,
  cx_w_i_24_hrs: null,
  patients_w_stay_grater_70_min: null,
  crash_carts_set_up_restocked: null,
  instruments_returned_unused: null,
  flashed_items: null,
  procedures_requiring_staff_grater_normal_ortho: null,
  procedures_requiring_grater_normal_plastics: null,
  procedures_requiring_grater_normal_for_ophthamolgy: null,
  procedures_requiring_grater_normal_general_baritics_other: null,
  ip_status_patients_held_over: null,
  of_hours_the_rcu_was_open_last_4_hrs: null,
  one_1_and_1_2_pts_currently_awaiting_placement: null,
  code_3s_stemi_or_strokes_last_4_hours: null,
  of_psa_si_pts: null,
  of_lost_doses: null,
  active_cases_in_the_work_queue: null,
  of_ir_vas_procedures: null,
  in_chair_minutes: null,
  pain_management: null,
  hi_flow_in_us: null,
  of_vents_in_use: null,
  of_bi_paps_in_use: null,
  of_c_paps_in_use_noc_only: null,
  of_loaner_trays_processed: null,
  lab_technologist: null,
  phlebotomist_clinical_services: null,
  education_orientation: null,
  contract_labor_tech: null,
  contract_labor_phlebotomy: null,
  histology: null,
  client_services: null,
  receptionist: null,
  health_plan_coordinator: null,
  medical_assistant: null,
  lead_medical_assistant: null,
  radiologist_technologist: null,
  athletic_trainer: null,
  urgent_cases_added_to_schedule: null,
  unplanned_providers_absent: null,
  no_show_patients_same_day_cancelation: null
};

function AddModal({
  isShow,
  callbackfunc,
  reloaddata,
  cost_center_number,
  contentId,
  previousShiftData,
  getprevAndCurrentShift,
  addgridData,
  cost_center_name,
  numbers,
  getPreAndCurr,
  getPrevShiftData,
  acuityNurse,
  formContent,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  const shiftLength = 4;
  const selector = useSelector((state) => state);
  const socket = useContext(SocketContext);
  const detail_data = selector.compensate.departmentdetail;
  let gridDataApiData = selector.compensate.getPreviousShiftData?.gridData;
  const costCenterData = useSelector(
    (state) => state.compensate.costCenterData
  );

  const directDataApiData =
    selector.compensate.getPreviousShiftData?.directData;

  const contentone = detail_data?.content ? detail_data.content.content_1 : {};
  const [formdata, setFormdata] = useState(init);
  const userType = JSON.parse(localStorage.getItem("psdata"))?.data?.user_type; //userType 0 for admin
  const validateCensus = formContent?.validate_census;
  const validateBeds = formContent?.beds;
  // const autoFillformdata = autoFillformdata
  const [successMsg, setSuccessmsg] = useState("");
  const [variance, setVariance] = useState({});
  const [patientData, setPatientData] = useState({});
  const [uosPatientData, setUosPatientData] = useState({});
  const [totalUosPatient, setTotalUosPatient] = useState(0);
  const [uosStaffingData, setUosStaffingData] = useState({});
  const [totalUosStaffing, setTotalUosStaffing] = useState(0);
  const [plannedHpous, setPlannedHpous] = useState(0);
  const [totalvariance, setTotalVar] = useState(0);
  const [totalPatientvariance, setTotalPatientVar] = useState(0);
  const [gridData, setGridData] = useState({});
  const activedatefilter = selector.compensate?.activedatefilter;
  const [showError, setShowError] = useState(false);
  const [isKeyEmpty, setIsKeyEmpty] = useState(false);
  const [rnTotal, setRnTotal] = useState(0);
  const [rnAvailableData, setRnAvailableData] = useState({});
  const [witRn, setWitRn] = useState(0);
  const [rnVariance, setRnVariance] = useState(0);
  const [formControlData, setFormControltData] = useState({});
  const [formShiftData, setFormShiftData] = useState([]);
  const [formShiftLabel, setFormShiftLabel] = useState({});
  const [bltChecked, setBltChecked] = useState(0);
  const [ldRnVariance, setLdRnVariance] = useState(0);
  const [nicuVariance, setNicuVariance] = useState(0);
  const [ppRnVariance, setppRnVariance] = useState(0);
  const [customRnVariance, setCustomRnVariance] = useState(0);
  const [totalRegCal, setTotalRegCal] = useState(0);
  const [unitServiceCal, setUnitServiceCal] = useState(0);
  const [procedureOneCal, setProcedureOneCal] = useState(0);
  const [procedureTwoCal, setProcedureTwoCal] = useState(0);
  const [itemsCal, setItemsCal] = useState(0);
  const [minutesCal, setMinutesCal] = useState(0);
  const [clinicVisitCal, setClinicVisitCal] = useState(0);
  const [iolMacPacuCal, setIolMacPacuCal] = useState(0);
  const [ofMinutesCal, setOfMinutesCal] = useState(0);
  const [lnCensusGrid, setlnCensusGrid] = useState(0);
  const [nicuCensusGrid, setNicuCensusGrid] = useState(0);
  const [nicuCensusData, setNicuCensusData] = useState({});
  const [ppCensusGrid, setPpCensusGrid] = useState(0);
  const [ppCensusData, setPpCensusData] = useState({});
  const [periopState, setPeriopState] = useState({});

  const dateselected = directDataApiData?.shift_date
    ? directDataApiData.shift_date
    : "";

  const timestamp = Date(dateselected);
  const date = Moment(Date(dateselected)).format("DD/MM/YYYY");

  useEffect(() => {
    setGridData(gridDataApiData);
    getFormData();
    getPeriopData();
    setIsKeyEmpty(false);
    setTotalPatientVar(0);
    setPatientData({});
    setNicuCensusData({});
    setPpCensusData({});
    setRnAvailableData({});
    setBltChecked(0);
    setRnTotal(0);
    setPlannedHpous(0);
    setTotalUosPatient(0);
    setTotalUosStaffing(0);
    setlnCensusGrid(0);
    setNicuCensusGrid(0);
    setPpCensusGrid(0);
  }, [gridDataApiData]);

  const getPeriopData = async () => {
      try {
        const paramData = {
          date: formatDashDate(activedatefilter?.shift_date),
        };
        const encryptData = dataEnCrypt(paramData);
        const resp = await axios.post(
          `${API_CALL_URL_PRIVATE}/department/getPeriopData`,
          { payloadData: encryptData }
        );
        const data = decryptedFunction(resp?.data?.responseData);
        setPeriopState(data?.data?.periopData);
      } catch (err) {
        console.log(err);
      }
  }

  const getFormData = async () => {
    try {
      const paramData = {
        cost_center_number: cost_center_number,
      };

      const encryptData = dataEnCrypt(paramData);
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/department/getshiftFormControl`,
        { payloadData: encryptData }
      );

      const data = decryptedFunction(resp?.data?.responseData);
      setFormShiftData(data?.data?.FormControl);
      setFormShiftLabel(data?.data?.formLabel);

      const convertData = async (dataArray) => {
        return dataArray?.reduce((acc, item) => {
          acc[item?.field_name] = {
            display: item?.display,
            required: item?.required,
            field_label: item?.field_label,
          };
          return acc;
        }, {});
      };

      const convertedFormData = await convertData(data?.data?.FormControl);
      setFormControltData(convertedFormData);
    } catch (err) {
      console.log(err);
    }
  };

  const finalPatientData = formShiftData
    ?.filter((item) => item.type === "patient_data" && item?.display !== 0)
    .sort((a, b) => a.display - b.display);

  const finalStaffingData = formShiftData
    ?.filter((item) => item.type === "staffing" && item?.display !== 0)
    .sort((a, b) => a.display - b.display);

  const finalAdditionalData = formShiftData
    ?.filter(
      (item) => item.type === "additional_data_tracking" && item?.display !== 0
    )
    .sort((a, b) => a.display - b.display);

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.target.name]: event.target.value,
    });

    setIsKeyEmpty(false);
    let el = document.querySelectorAll(".apicall");
    setpartValue(event);
    setvarianceValue(event);
    setRnHourValue(event);

    if (
      costCenterData?.interval === 24 &&
      !!activedatefilter?.display_planned_hpous
    ) {
      getUosPatient(event);
      getUosStaffing(event);
    }
  };

  // Patient key Inputs
  const setpartValue = (event) => {
    if (event.target.name == "two_one") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { two_one, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      //setFormdata(...formdata, one_one);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_one") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_one, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      //setFormdata(...formdata, one_one);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_two") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_two, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_three") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_three, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_four") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_four, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_five") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_five, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "one_six") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { one_six, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "observation_patients") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { observation_patients, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "inpatient_boarders") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { inpatient_boarders, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "total_patients_three") {
      const optional = event.target.value;
      const sum = totalPatientvariance;
      setTotalPatientVar(totalPatientvariance);
      getDirectDataById("Grid", sum, event, optional);
    } else if (event.target.name == "pt_type1_0_20") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { pt_type1_0_20, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "pt_type2_21_28") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { pt_type2_21_28, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "pt_type3_29_68") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { pt_type3_29_68, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "of_roomed_er_patients") {
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { of_roomed_er_patients, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);
      getDirectDataById("Grid", sum, event);
    } else if (event.target.name == "labor_patients_recovery") {
      // Total Patient Total
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { labor_patients_recovery, ...rest } = patientData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);

      // Ln Census Total = labor_patients_recovery
      const lnCensusTotal = Number(event.target.value);
      setlnCensusGrid(lnCensusTotal);
      getGridData(lnCensusTotal, nicuCensusGrid, ppCensusGrid, sum, event);
    } else if (event.target.name == "nicu_cpap") {
      // Total Patient Total
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { nicu_cpap: patientNicuCpap, ...patientRest } = patientData;
      const sum =
        Object.values(patientRest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);

      // Nicu Census Total = nicu_cpap + nicu_feeder_growers
      setNicuCensusData({
        ...nicuCensusData,
        [event.target.name]: event.target.value,
      });
      const { nicu_cpap: censusNicuCpap, ...rest } = nicuCensusData;
      const nicuCensusTotal =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setNicuCensusGrid(nicuCensusTotal);
      getGridData(lnCensusGrid, nicuCensusTotal, ppCensusGrid, sum, event);
    } else if (event.target.name == "nicu_feeder_growers") {
      // Total Patient Total
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { nicu_feeder_growers: patientNicuFeeder, ...patientRest } =
        patientData;
      const sum =
        Object.values(patientRest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);

      // Nicu Census Total = nicu_cpap + nicu_feeder_growers
      setNicuCensusData({
        ...nicuCensusData,
        [event.target.name]: event.target.value,
      });
      const { nicu_feeder_growers: censusNicuFeeder, ...rest } = nicuCensusData;
      const nicuCensusTotal =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setNicuCensusGrid(nicuCensusTotal);
      getGridData(lnCensusGrid, nicuCensusTotal, ppCensusGrid, sum, event);
    } else if (event.target.name == "post_op_pp_one_two") {
      // Total Patient Total
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { post_op_pp_one_two: patientPostOp, ...patientRest } = patientData;
      const sum =
        Object.values(patientRest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);

      // PP Census Total = post_op_pp_one_two / 2  + stable_pp_one_three / 3
      const newValue = Math.round(Number(event.target.value) / 2);
      setPpCensusData({
        ...ppCensusData,
        [event.target.name]: newValue,
      });
      const { post_op_pp_one_two: censusPostOp, ...rest } = ppCensusData;
      const ppCensusTotal =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        newValue;
      setPpCensusGrid(ppCensusTotal);
      getGridData(lnCensusGrid, nicuCensusGrid, ppCensusTotal, sum, event);
    } else if (event.target.name == "stable_pp_one_three") {
      // Total Patient Total
      setPatientData({
        ...patientData,
        [event.target.name]: event.target.value,
      });
      const { stable_pp_one_three: patientStablePp, ...patientRest } =
        patientData;
      const sum =
        Object.values(patientRest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(event.target.value);
      setTotalPatientVar(sum);

      // PP Census Total = post_op_pp_one_two / 2  + stable_pp_one_three / 3
      const newValue = Math.round(Number(event.target.value) / 3);
      setPpCensusData({
        ...ppCensusData,
        [event.target.name]: newValue,
      });
      const { stable_pp_one_three: CensusStablePp, ...rest } = ppCensusData;
      const ppCensusTotal =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        newValue;
      setPpCensusGrid(ppCensusTotal);
      getGridData(lnCensusGrid, nicuCensusGrid, ppCensusTotal, sum, event);
    } else {
      finalPatientData.map((data) => {
        if (data?.field_name === event.target.name) {
          const sum = totalPatientvariance;
          getDirectDataById("Grid", sum, event);
        }
      });
    }
  };

  // Staffing key Inputs
  const setvarianceValue = (event) => {
    if (event.target.name == "charge") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.required_charge,
      });
      const { charge, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.required_charge);
      setTotalVar(sum);
    } else if (event.target.name == "cna") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.required_cna,
      });
      const { cna, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.required_cna);
      setTotalVar(sum);
    } else if (event.target.name == "orientation") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.orientation,
      });
      const { orientation, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.orientation);
      setTotalVar(sum);
    } else if (event.target.name == "nurses") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.required_nurses,
      });
      const { nurses, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.required_nurses);
      setTotalVar(sum);
    } else if (event.target.name == "res_brk_rn") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.required_res_brk_rn,
      });
      const { res_brk_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.required_res_brk_rn);
      setTotalVar(sum);
    } else if (event.target.name == "unit_sec") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.unit_sec,
      });
      const { unit_sec, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.unit_sec);
      setTotalVar(sum);
    } else if (event.target.name == "sitter") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.sitter,
      });
      const { sitter, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.sitter);
      setTotalVar(sum);
    } else if (event.target.name == "techs_cna") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.techs_cna,
      });
      const { techs_cna, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.techs_cna);
      setTotalVar(sum);
    } else if (event.target.name == "psa_si") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.psa_si,
      });
      const { psa_si, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.psa_si);
      setTotalVar(sum);
    } else if (event.target.name == "psa_medical") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.psa_medical,
      });
      const { psa_medical, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.psa_medical);
      setTotalVar(sum);
    } else if (event.target.name == "blt") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.blt,
      });
      const { blt, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.blt);
      setTotalVar(sum);
    } else if (event.target.name == "other") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.other,
      });
      const { other, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.other);
      setTotalVar(sum);
    } else if (event.target.name == "soc_worker_ft") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.soc_worker_ft,
      });
      const { soc_worker_ft, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.soc_worker_ft);
      setTotalVar(sum);
    } else if (event.target.name == "soc_worker_pt") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.soc_worker_pt,
      });
      const { soc_worker_pt, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.soc_worker_pt);
      setTotalVar(sum);
    } else if (event.target.name == "occ_therapist") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.occ_therapist,
      });
      const { occ_therapist, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.occ_therapist);
      setTotalVar(sum);
    } else if (event.target.name == "pp_rn") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.pp_rn,
      });
      const { pp_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.pp_rn);
      setTotalVar(sum);
    } else if (event.target.name == "l_and_d_rn") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.l_and_d_rn,
      });
      const { l_and_d_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.l_and_d_rn);
      setTotalVar(sum);
    } else if (event.target.name == "nicu_peds_rn") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.nicu_peds_rn,
      });
      const { nicu_peds_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.nicu_peds_rn);
      setTotalVar(sum);
    } else if (event.target.name == "triage_rn") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.triage_rn,
      });
      const { triage_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.triage_rn);
      setTotalVar(sum);
    } else if (event.target.name == "lead_tech") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.lead_tech,
      });
      const { lead_tech, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.lead_tech);
      setTotalVar(sum);
    } else if (event.target.name == "clinical_coordinator") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - gridData?.clinical_coordinator,
      });
      const { clinical_coordinator, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.clinical_coordinator);
      setTotalVar(sum);
    } else if (event.target.name == "front_desk") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.front_desk,
      });
      const { front_desk, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.front_desk);
      setTotalVar(sum);
    } else if (event.target.name == "ivs") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.ivs,
      });
      const { ivs, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.ivs);
      setTotalVar(sum);
    } else if (event.target.name == "ed_reg") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.ed_reg,
      });
      const { ed_reg, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.ed_reg);
      setTotalVar(sum);
    } else if (event.target.name == "seven_ave_reg") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.seven_ave_reg,
      });
      const { seven_ave_reg, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.seven_ave_reg);
      setTotalVar(sum);
    } else if (event.target.name == "rt") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.rt,
      });
      const { rt, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.rt);
      setTotalVar(sum);
    } else if (event.target.name == "support") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.support,
      });
      const { support, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.support);
      setTotalVar(sum);
    } else if (event.target.name == "scrub_tech") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.scrub_tech,
      });
      const { scrub_tech, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.scrub_tech);
      setTotalVar(sum);
    } else if (event.target.name == "rn_preceptor") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.rn_preceptor,
      });
      const { rn_preceptor, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.rn_preceptor);
      setTotalVar(sum);
    } else if (event.target.name == "rn_board_runner") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.rn_board_runner,
      });
      const { rn_board_runner, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.rn_board_runner);
      setTotalVar(sum);
    } else if (event.target.name == "scrub_tech_preceptor") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - gridData?.scrub_tech_preceptor,
      });
      const { scrub_tech_preceptor, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.scrub_tech_preceptor);
      setTotalVar(sum);
    } else if (event.target.name == "scrub_tech_board_runner") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - gridData?.scrub_tech_board_runner,
      });
      const { scrub_tech_board_runner, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.scrub_tech_board_runner);
      setTotalVar(sum);
    } else if (event.target.name == "rnfa_stfa") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.rnfa_stfa,
      });
      const { rnfa_stfa, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.rnfa_stfa);
      setTotalVar(sum);
    } else if (event.target.name == "rcu_rn") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.rcu_rn,
      });
      const { rcu_rn, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.rcu_rn);
      setTotalVar(sum);
    } else if (event.target.name == "lab_technologist") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.lab_technologist,
      });
      const { lab_technologist, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.lab_technologist);
      setTotalVar(sum);
    } else if (event.target.name == "phlebotomist_clinical_services") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - gridData?.phlebotomist_clinical_services,
      });
      const { phlebotomist_clinical_services, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.phlebotomist_clinical_services);
      setTotalVar(sum);
    } else if (event.target.name == "education_orientation") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - gridData?.education_orientation,
      });
      const { education_orientation, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.education_orientation);
      setTotalVar(sum);
    } else if (event.target.name == "contract_labor_tech") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.contract_labor_tech,
      });
      const { contract_labor_tech, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.contract_labor_tech);
      setTotalVar(sum);
    } else if (event.target.name == "contract_labor_phlebotomy") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - gridData?.contract_labor_phlebotomy,
      });
      const { contract_labor_phlebotomy, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.contract_labor_phlebotomy);
      setTotalVar(sum);
    } else if (event.target.name == "histology") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.histology,
      });
      const { histology, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.histology);
      setTotalVar(sum);
    } else if (event.target.name == "client_services") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.client_services,
      });
      const { client_services, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.client_services);
      setTotalVar(sum);
    } else if (event.target.name == "receptionist") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.receptionist,
      });
      const { receptionist, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.receptionist);
      setTotalVar(sum);
    } else if (event.target.name == "health_plan_coordinator") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - gridData?.health_plan_coordinator,
      });
      const { health_plan_coordinator, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.health_plan_coordinator);
      setTotalVar(sum);
    } else if (event.target.name == "medical_assistant") {
      setVariance({
        ...variance,
        [event.target.name]: event.target.value - gridData?.medical_assistant,
      });
      const { medical_assistant, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.medical_assistant);
      setTotalVar(sum);
    } else if (event.target.name == "lead_medical_assistant") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - gridData?.lead_medical_assistant,
      });
      const { lead_medical_assistant, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.lead_medical_assistant);
      setTotalVar(sum);
    } else if (event.target.name == "radiologist_technologist") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - gridData?.radiologist_technologist,
      });
      const { radiologist_technologist, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.radiologist_technologist);
      setTotalVar(sum);
    } else if (event.target.name == "athletic_trainer") {
      setVariance({
        ...variance,
        [event.target.name]:
          event.target.value - gridData?.athletic_trainer,
      });
      const { athletic_trainer, ...rest } = variance;
      const sum =
        Object.values(rest).reduce((a, b) => a + b, 0) +
        (event.target.value - gridData?.athletic_trainer);
      setTotalVar(sum);
    }
  };

  // PLANNED HPOUS CALCULATION
  useEffect(() => {
    costCenterData?.interval === 24 &&
      !!activedatefilter?.display_planned_hpous &&
      calculateUosHpous(
        totalUosPatient,
        totalUosStaffing,
        ofMinutesCal,
        clinicVisitCal,
        procedureOneCal,
        procedureTwoCal,
        iolMacPacuCal
      );
  }, [
    ofMinutesCal,
    clinicVisitCal,
    procedureOneCal,
    procedureTwoCal,
    iolMacPacuCal,
  ]);

  // PLANNED HPOUS CALCULATION
  const calculateFixedPosition = () => {
    const fixedNames = [
      "fixed_position_5_1",
      "fixed_position_5_2",
      "fixed_position_5_3",
      "fixed_position_5_4",
      "fixed_position_5_5",
      "fixed_position_5_8",
      "fixed_position_5_5_2",
      "fixed_position_7_1",
      "fixed_position_7_2",
      "fixed_position_7_3",
      "fixed_position_7_4",
      "fixed_position_7_5",
      "fixed_position_7_5_2",
      "fixed_position_7_8",
    ];

    const filteredStaffingData = formShiftData?.filter(
      (item) =>
        item.type === "staffing" &&
        fixedNames.includes(item.field_name) &&
        item.field_label !== null
    );

    const total = filteredStaffingData?.reduce((sum, item) => {
      const value = SHIFT_FORM?.STAFFING_FIXED_POSITION[item.field_name] || 0;
      return sum + value;
    }, 0);

    return total?.toFixed(2);
  };

  // PLANNED HPOUS CALCULATION
  const getUosPatient = (event) => {
    const { name, value } = event.target;
    // const validNames = [
    //   "front_desk_reg",
    //   "seven_ave_reg_patient",
    //   "ed_reg_patient",
    //   "procedures",
    //   "calculated_procedures_1",
    //   "calculated_procedures_2",
    //   "actual_minutes",
    //   "of_minutes",
    //   "iol_mac_pacu_minutes",
    //   "items",
    //   "in_clinic_visits",
    // ];

    const patientUos = [
      "front_desk_reg",
      "seven_ave_reg_patient",
      "ed_reg_patient",
      "procedures",
      "actual_minutes",
      "items",
    ];

    if (patientUos.includes(name)) {
      const updatedData = {
        ...uosPatientData,
        [name]: value,
      };

      const sum = Object.values(updatedData).reduce(
        (a, b) => Number(a) + Number(b),
        0
      );

      setUosPatientData(updatedData);
      setTotalUosPatient(sum);
      calculateUosHpous(sum, totalUosStaffing);
    }
  };

  // PLANNED HPOUS CALCULATION
  const getUosStaffing = (event) => {
    const { name, value } = event.target;

      finalStaffingData.map((data) => {
      if (data?.field_name === name) {
      const updatedData = {
        ...uosStaffingData,
        [name]: value,
      };

      const sum = Object.values(updatedData).reduce(
        (a, b) => Number(a) + Number(b),
        0
      );

      setUosStaffingData(updatedData);
      setTotalUosStaffing(sum);
      calculateUosHpous(totalUosPatient, sum);
      }
    });

    // if (validNames.includes(name)) {
    //   const updatedData = {
    //     ...uosStaffingData,
    //     [name]: value,
    //   };

    //   const sum = Object.values(updatedData).reduce(
    //     (a, b) => Number(a) + Number(b),
    //     0
    //   );

    //   setUosStaffingData(updatedData);
    //   setTotalUosStaffing(sum);
    //   calculateUosHpous(totalUosPatient, sum);
    // }
  };

  // PLANNED HPOUS CALCULATION
  const calculateUosHpous = (
    uosPatient,
    uosStaffing,
    ofMinutes,
    clinicVisit,
    procedureOne,
    procedureTwo,
    iolMacPacu
  ) => {
    const ofMinutesVal =
      (formControlData?.of_minutes &&
      formControlData?.of_minutes?.display !== 0) 
      ? (ofMinutes ? Number(ofMinutes) : Number(ofMinutesCal))
      :  Number(0);
    const clinicVisitVal =
      formControlData?.in_clinic_visits &&
      formControlData?.in_clinic_visits?.display !== 0
        ? clinicVisit
          ? Number(clinicVisit)
          : Number(clinicVisitCal)
        :  Number(0);
    const procedureOneVal =
    formControlData?.calculated_procedures_1 &&
    formControlData?.calculated_procedures_1?.display !== 0
      ? procedureOne
        ? Number(procedureOne)
        : Number(procedureOneCal)
      :  Number(0);
    const procedureTwoVal =
      formControlData?.calculated_procedures_2 &&
      formControlData?.calculated_procedures_2?.display !== 0
        ? procedureTwo
          ? Number(procedureTwo)
          : Number(procedureTwoCal)
        : Number(0);
    const iolMacPacuVal =
      formControlData?.iol_mac_pacu_minutes &&
      formControlData?.iol_mac_pacu_minutes?.display !== 0
        ? iolMacPacu
          ? Number(iolMacPacu)
          : Number(iolMacPacuCal)
        : Number(0);

    const uos24 =
      Number(uosPatient) +
      ofMinutesVal +
      clinicVisitVal +
      procedureOneVal +
      procedureTwoVal +
      iolMacPacuVal;

    const worked_hour = Number(uosStaffing);

    const planned_hpous = worked_hour / uos24;
     if (isFinite(planned_hpous)) {
       const roundedValue = +planned_hpous.toFixed(3);
       setPlannedHpous(roundedValue);
     } else {
       setPlannedHpous(0);
     }
  };

  // RN Available Hours Formula
  const setRnHourValue = (event) => {
    if (event.target.name == "two_one") {
      const rnHourSum = 2 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { two_one, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_one") {
      const rnHourSum = 1 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_one, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
      //setFormdata(...formdata, one_one);
    } else if (event.target.name == "one_two") {
      const rnHourSum = 0.5 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_two, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_three") {
      const rnHourSum = 0.33 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_three, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_four") {
      const rnHourSum = 0.25 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_four, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_five") {
      const rnHourSum = 0.2 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_five, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "one_six") {
      const rnHourSum = 0.1 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { one_six, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "nurses") {
      const { ...rest } = rnAvailableData;
      const sum =
        Number(event.target.value) -
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0);
      const rnSum = sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "observation_patients") {
      const rnHourSum = 0.2 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { observation_patients, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    } else if (event.target.name == "inpatient_boarders") {
      const rnHourSum = 0.2 * Number(event.target.value);
      setRnAvailableData({
        ...rnAvailableData,
        [event.target.name]: rnHourSum,
      });
      const { inpatient_boarders, ...rest } = rnAvailableData;
      const sum =
        Object.values(rest).reduce((a, b) => Number(a) + Number(b), 0) +
        Number(rnHourSum);
      const rnSum = formdata?.nurses - sum;
      setRnTotal(rnSum?.toFixed(2));
    }
  };

  const getFirstEmptyKey = (formdata) => {
    const keysToIgnore = [
      "cost_center_number",
      "shift_date",
      "shift_time",
      "total_patients",
      "id",
      "currentShiftNumber",
    ];

    const emptyKeys = Object.keys(formdata)
      .filter((key) => !keysToIgnore.includes(key))
      .filter((key) => formControlData[key]?.required === 1)
      .filter(
        (key) =>
          formdata[key] === undefined ||
          formdata[key] === null ||
          formdata[key] === ""
      );

    if (emptyKeys.length > 1) {
      return `Please Enter Missing Data`;
    } else if (emptyKeys.length === 1) {
      return (
        "Please Enter Missing" + " " + SHIFT_FORM.VALIDATION_NAMES[emptyKeys[0]]
      );
    } else {
      return false;
    }
  };

  const submitHandle = async () => {
    let submitdata = formdata;
    const validateFormData = formControlData;

    let ischecked = [];
    submitdata["id"] = "";
    submitdata["grid_variance"] = totalvariance;
    submitdata["rn_available_hours"] = rnTotal;
    submitdata["wit_rn"] = witRn;
    submitdata["rn_variance"] = rnVariance;
    submitdata["l_and_d_rn_variance"] = ldRnVariance;
    submitdata["custom_rn_variance"] = customRnVariance;
    submitdata["nicu_variance"] = nicuVariance;
    submitdata["pp_rn_variance"] = ppRnVariance;
    submitdata["calculated_total_registrations"] = totalRegCal;
    submitdata["calculated_units_of_service"] = unitServiceCal;
    submitdata["calculated_procedures_1"] = procedureOneCal;
    submitdata["calculated_procedures_2"] = procedureTwoCal;
    submitdata["calculated_items"] = itemsCal;
    submitdata["calculated_minutes"] = minutesCal;
    submitdata["in_clinic_visits"] = clinicVisitCal;
    submitdata["iol_mac_pacu_minutes"] = iolMacPacuCal;
    submitdata["of_minutes"] = ofMinutesCal;

    // Variance Staffing fields
    submitdata["charge_variance"] = variance?.charge;
    submitdata["cna_variance"] = variance?.cna;
    submitdata["orientation_variance"] = variance?.orientation;
    submitdata["nurses_variance"] = variance?.nurses;
    submitdata["res_brk_rn_variance"] = variance?.res_brk_rn;
    submitdata["unit_sec_variance"] = variance?.unit_sec;
    submitdata["sitter_variance"] = variance?.sitter;
    submitdata["techs_cna_grid_variance"] = variance?.techs_cna;
    submitdata["psa_si_grid_variance"] = variance?.psa_si;
    submitdata["psa_medical_grid_variance"] = variance?.psa_medical;
    submitdata["blt_grid_variance"] = variance?.blt;
    submitdata["other_grid_variance"] = variance?.other;
    submitdata["soc_worker_ft_grid_variance"] = variance?.soc_worker_ft;
    submitdata["soc_worker_pt_grid_variance"] = variance?.soc_worker_pt;
    submitdata["occ_therapist_grid_variance"] = variance?.occ_therapist;
    submitdata["pp_rn_grid_variance"] = variance?.pp_rn;
    submitdata["l_and_d_rn_grid_variance"] = variance?.l_and_d_rn;
    submitdata["nicu_peds_rn_grid_variance"] = variance?.nicu_peds_rn;
    submitdata["triage_rn_grid_variance"] = variance?.triage_rn;
    submitdata["lead_tech_grid_variance"] = variance?.lead_tech;
    submitdata["clinical_coordinator_grid_variance"] =
      variance?.clinical_coordinator;
    submitdata["front_desk_grid_variance"] = variance?.front_desk;
    submitdata["ivs_grid_variance"] = variance?.ivs;
    submitdata["ed_reg_grid_variance"] = variance?.ed_reg;
    submitdata["seven_ave_reg_grid_variance"] = variance?.seven_ave_reg;
    submitdata["rt_grid_variance"] = variance?.rt;
    submitdata["support_grid_variance"] = variance?.support;
    submitdata["scrub_tech_grid_variance"] = variance?.scrub_tech;
    submitdata["rn_preceptor_grid_variance"] = variance?.rn_preceptor;
    submitdata["rn_board_runner_grid_variance"] = variance?.rn_board_runner;
    submitdata["scrub_tech_preceptor_grid_variance"] =
      variance?.scrub_tech_preceptor;
    submitdata["scrub_tech_board_runner_grid_variance"] =
      variance?.scrub_tech_board_runner;
    submitdata["rnfa_stfa_grid_variance"] = variance?.rnfa_stfa;
    submitdata["rcu_rn_grid_variance"] = variance?.rcu_rn;
    submitdata["lab_technologist_grid_variance"] = variance?.lab_technologist;
    submitdata["phlebotomist_clinical_services_grid_variance"] =
      variance?.phlebotomist_clinical_services;
    submitdata["education_orientation_grid_variance"] =
      variance?.education_orientation;
    submitdata["contract_labor_tech_grid_variance"] =
      variance?.contract_labor_tech;
    submitdata["contract_labor_phlebotomy_grid_variance"] =
      variance?.contract_labor_phlebotomy;
    submitdata["histology_grid_variance"] = variance?.histology;
    submitdata["client_services_grid_variance"] = variance?.client_services;
    submitdata["receptionist_grid_variance"] = variance?.receptionist;
    submitdata["health_plan_coordinator_grid_variance"] =
      variance?.health_plan_coordinator;
    submitdata["medical_assistant_grid_variance"] =
      variance?.medical_assistant;
    submitdata["lead_medical_assistant_grid_variance"] =
      variance?.lead_medical_assistant;
    submitdata["radiologist_technologist_grid_variance"] =
      variance?.radiologist_technologist;
    submitdata["athletic_trainer_grid_variance"] =
      variance?.athletic_trainer;
    submitdata["grid_missing"] = gridData;

    submitdata["populated_minutes_1"] = periopState?.populated_minutes_1;
    submitdata["populated_minutes_2"] = periopState?.populated_minutes_2;
    submitdata["populated_minutes_3"] = periopState?.populated_minutes_3;
    submitdata["populated_minutes_4"] = periopState?.populated_minutes_4;

    // Hpous Calculation 
     if (
       costCenterData?.interval === 24 &&
       !!activedatefilter?.display_planned_hpous
     ) {
       submitdata["planned_hpuos"] = plannedHpous;
     } else {
       delete submitdata["planned_hpuos"];
     }

    // census
    submitdata["l_and_d_census"] = lnCensusGrid;
    submitdata["nicu_census"] = nicuCensusGrid;
    submitdata["pp_census"] = ppCensusGrid;

    submitdata["census_added_date_filter"] = gridData?.added_date_filter;

    if (!formControlData?.total_patients_three?.display) {
      submitdata["total_patients_three"] = 0;
    }

    let timestamp = activedatefilter?.shift_date
      ? activedatefilter.shift_date.split("/")
      : [];
    //console.log(timestamp, 'converttimestamp');
    timestamp =
      timestamp.length > 0
        ? `${timestamp[1]}-${timestamp[0]}-${timestamp[2]}`
        : "";
    submitdata["cost_center_number"] = cost_center_number;
    submitdata["shift_date"] = timestamp;
    submitdata["shift_time"] = activedatefilter?.shift_time + ":00";

    const checkval = Object.keys(submitdata).map((key, index) => {
      if (key != "id" && key != "currentShiftNumber") {
        if (validateFormData[key]?.required === 1) {
          if (submitdata[key] === null || submitdata[key] === "") {
            ischecked.push(false);
            if (key == "comment") {
              var el = document.querySelector("textarea[name=" + key + "]");
            } else {
              var el = document.querySelector("input[name=" + key + "]");
            }

            if (el != null) {
              setFormdata((prevFormdata) => ({
                ...prevFormdata,
                [key]: "",
              }));
              el.classList.add("redborder");
              const getResult = getFirstEmptyKey(formdata);
              setIsKeyEmpty(getResult);
            }
          } else {
            if (key == "comment") {
              var el = document.querySelector("textarea[name=" + key + "]");
            } else {
              var el = document.querySelector("input[name=" + key + "]");
            }
            if (el != null) {
              el.classList.remove("redborder");
            }
            ischecked.push(true);
          }
        }
      }
    });
    const checked = ischecked.every((element) => element === true);
    if (!checked) {
      return false;
    }

    if (
      validateFormData["total_patients"]?.display !== 0 &&
      validateFormData["total_patients_two"]?.display !== 0 &&
      validateFormData["total_patients_two"]?.required === 1
    ) {
      if (+submitdata?.total_patients_two !== submitdata?.total_patients) {
        setIsKeyEmpty(
          "Please check Total Patients and Patient Acuity. They do not match."
        );
        return false;
      }
    }

    // Census cant be higher than beds
    const census = formControlData.total_patients_three.display
      ? formdata.total_patients_three
      : formdata.total_patients;
    if (validateCensus && census > validateBeds) {
      setIsKeyEmpty("Census can't be higher than beds.");
      return false;
    }

    for (let key in submitdata) {
      if (
        key !== "comment" &&
        key !== "id" &&
        (submitdata[key] === null ||
          submitdata[key] === undefined ||
          submitdata[key] === "")
      ) {
        submitdata[key] = 0;
      }
    }

    try {
      const encryptData = dataEnCrypt(submitdata);
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/department/addupdateDirectEntery",
        { payloadData: encryptData }
      );
      const data = decryptedFunction(resp?.data?.responseData);
      // const { data } = await resp;
      //console.log("response data", data);
      setSuccessmsg(data.message);
      socket.emit("csvAdd", { csvAdd: true, csvUpdate: false });
      //   if (data.message == "Data Saved Succesfully") {

      reloaddata(data?.DirectEntery?._id);
      setFormdata(init);
      setTotalPatientVar(0);
      setVariance({});
      setTotalVar(0);
      callbackfunc(false, true, data.message);
      //   }
    } catch (error) {
      console.log(error);
      setShowError(true);
      setSuccessmsg(error.response.data.message);
    }
  };

  // patient data grid calculation and admin copy grid calculation
  const getDirectDataById = async (title, sum, event, optional) => {
    try {
      // const resp = await axios.post(
      //   API_CALL_URL_PRIVATE + "/department/getDirectDataById",
      //   { id: directDataApiData?._id }
      // );
      // const { data } = await resp;

      if (title !== "Grid") {
        if (title == "Staffing") {
          // Staffing - Variance Total
          let finalStaffingObj = finalStaffingData.reduce((obj, val) => {
            obj[val.field_name] =
              (directDataApiData?.[val.field_name] || 0) -
              (gridData?.[SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]] ||
                0);
            return obj;
          }, {});

          // Staffing - Previous Data copy
          let varianceStObj = finalStaffingData.reduce((obj, val) => {
            obj[val.field_name] = directDataApiData?.[val.field_name] || 0;
            return obj;
          }, {});

          setFormdata({
            ...formdata,
            ...varianceStObj,
            total_patients: totalPatientvariance,
          });

          setVariance(finalStaffingObj);
          setRnTotal((directDataApiData?.nurses - rnTotal)?.toFixed(2));
          setTotalVar(
            Object.values(finalStaffingObj).reduce((a, b) => a + b, 0)
          );

          // Planned Hpous Staffing Calculation

          const uosStaffingFilter = finalStaffingData
           ?.reduce((obj, val) => {
            obj[val.field_name] = directDataApiData?.[val.field_name] || 0;
            return obj;
          }, {});
          setUosStaffingData(uosStaffingFilter);

          const uosStaffingSum = Object.values(uosStaffingFilter).reduce(
            (a, b) => a + b,
            0
          );

          setTotalUosStaffing(uosStaffingSum);
          calculateUosHpous(totalUosPatient, uosStaffingSum);
        }

        if (title == "PatientData") {
          // isPatientCalculations is calculation field in patient data thats dont include in copy data
          let isPatientCalculations = [
            "rn_available_hours",
            "wit_rn",
            "rn_variance",
            "l_and_d_rn_variance",
            "nicu_variance",
            "pp_rn_variance",
            "custom_rn_variance",
            "calculated_units_of_service",
            "calculated_procedures_1",
            "calculated_procedures_2",
            "calculated_items",
            "calculated_minutes",
            "calculated_total_registrations",
            "in_clinic_visits",
            "iol_mac_pacu_minutes",
            "of_minutes",
          ];

          let patientDataObj = finalPatientData
            .filter((item) => !isPatientCalculations.includes(item.field_name))
            .reduce((obj, val) => {
              obj[val.field_name] = directDataApiData?.[val.field_name] || 0;
              return obj;
            }, {});

          // isPatientSum is total sum of cenus for total patients sum
          let isPatientSum = [
            "two_one",
            "one_one",
            "one_two",
            "one_three",
            "one_four",
            "one_five",
            "one_six",
            "observation_patients",
            "inpatient_boarders",
            "pt_type1_0_20",
            "pt_type2_21_28",
            "pt_type3_29_68",
            "of_roomed_er_patients",
            "labor_patients_recovery",
            "nicu_cpap",
            "nicu_feeder_growers",
            "post_op_pp_one_two",
            "stable_pp_one_three",
          ];

          // Patient Calculation - Previous Data copy
          let patientFilter = finalPatientData
            .filter((item) => isPatientSum.includes(item.field_name))
            .reduce((obj, val) => {
              obj[val.field_name] = directDataApiData?.[val.field_name] || 0;
              return obj;
            }, {});
          setPatientData(patientFilter);

          //lnCensusData sum
          let lnCensusTotal =
            formControlData?.labor_patients_recovery &&
            formControlData?.labor_patients_recovery?.display !== 0
              ? directDataApiData?.labor_patients_recovery || 0
              : 0;
          setlnCensusGrid(lnCensusTotal);

          // nicuCensusData sum
          let isNicuCensus = ["nicu_cpap", "nicu_feeder_growers"];

          let nicuFilter = finalPatientData
            .filter((item) => isNicuCensus.includes(item.field_name))
            .reduce((obj, val) => {
              obj[val.field_name] = directDataApiData?.[val.field_name] || 0;
              return obj;
            }, {});
          setNicuCensusData(nicuFilter);

          const nicuCensusTotal = Object.values(nicuFilter).reduce(
            (a, b) => a + b,
            0
          );
          setNicuCensusGrid(nicuCensusTotal);

          // ppCensusData sum
          let isPpCensus = ["post_op_pp_one_two", "stable_pp_one_three"];

          let ppFilter = finalPatientData
            .filter((item) => isPpCensus.includes(item.field_name))
            .reduce((obj, val) => {
              if (val.field_name === "post_op_pp_one_two") {
                obj[val.field_name] =
                  Math.round(directDataApiData?.[val.field_name] / 2) || 0;
              } else if (val.field_name === "stable_pp_one_three") {
                obj[val.field_name] =
                  Math.round(directDataApiData?.[val.field_name] / 3) || 0;
              }
              return obj;
            }, {});
          setPpCensusData(ppFilter);
          const ppCensusTotal = Object.values(ppFilter).reduce(
            (a, b) => a + b,
            0
          );
          setPpCensusGrid(ppCensusTotal);

          // HPUOS for future intervals - UOS calculation based on Patient Data

           const patientUos = [
             "front_desk_reg",
             "seven_ave_reg_patient",
             "ed_reg_patient",
             "procedures",
             "actual_minutes",
             "items",
           ];

           const uosPatientFilter = finalPatientData
             ?.filter((handle) => patientUos.includes(handle.field_name))
             ?.reduce((obj, val) => {
              obj[val.field_name] = directDataApiData?.[val.field_name] || 0;
              return obj;
             }, {});
           setUosPatientData(uosPatientFilter);

            const uosPatientSum = Object.values(uosPatientFilter).reduce(
              (a, b) => a + b,
              0
            );

           setTotalUosPatient(uosPatientSum);
           calculateUosHpous(uosPatientSum, totalUosStaffing);


          // total census count
          let TotalPatientsCount = finalPatientData
            .filter((item) => isPatientSum.includes(item.field_name))
            .map((item) => directDataApiData?.[item.field_name] || 0)
            .reduce((acc, val) => acc + val, 0);

          setTotalPatientVar(TotalPatientsCount);

          const getgridparams = {
            cost_center_number: cost_center_number,
            shift_time: activedatefilter.shift_time + ":00",
            shift_date: formatDashDate(activedatefilter?.shift_date),
            census: TotalPatientsCount,
            l_and_d_census: lnCensusTotal,
            nicu_census: nicuCensusTotal,
            pp_census: ppCensusTotal,
          };

          const encryptData = dataEnCrypt(getgridparams);

          let addgriddata = await axios.post(
            API_CALL_URL_PRIVATE + "/department/getGridData",
            { payloadData: encryptData }
          );

          const data = decryptedFunction(addgriddata?.data?.responseData);
          //  console.log("addgridresult", addgridresult);
          // let addgridresult = await addgriddata;
          const gridresult = data?.data?.gridData;

          setGridData(gridresult);

          //Rn variable calculation - Admin
          let RnHours = {
            two_one:
              (formControlData?.two_one && formControlData.two_one.display !== 0
                ? directDataApiData?.two_one
                : 0) * 2,
            one_one:
              (formControlData?.one_one && formControlData.one_one.display !== 0
                ? directDataApiData?.one_one
                : 0) * 1,
            one_two:
              (formControlData?.one_two && formControlData.one_two.display !== 0
                ? directDataApiData?.one_two
                : 0) * 0.5,
            one_three:
              (formControlData?.one_three &&
              formControlData.one_three.display !== 0
                ? directDataApiData?.one_three
                : 0) * 0.33,
            one_four:
              (formControlData?.one_four &&
              formControlData.one_four.display !== 0
                ? directDataApiData?.one_four
                : 0) * 0.25,
            one_five:
              (formControlData?.one_five &&
              formControlData.one_five.display !== 0
                ? directDataApiData?.one_five
                : 0) * 0.2,
            one_six:
              (formControlData?.one_six && formControlData.one_six.display !== 0
                ? directDataApiData?.one_six
                : 0) * 0.1,
            observation_patients:
              (formControlData?.observation_patients &&
              formControlData.observation_patients.display !== 0
                ? directDataApiData?.observation_patients
                : 0) * 0.2,
            inpatient_boarders:
              (formControlData?.inpatient_boarders &&
              formControlData.inpatient_boarders.display !== 0
                ? directDataApiData?.inpatient_boarders
                : 0) * 0.2,
          };

          setRnAvailableData(RnHours);

          const rnTotalSum =
            2 *
              (formControlData?.two_one &&
              formControlData?.two_one?.display !== 0
                ? directDataApiData?.two_one || 0
                : 0) +
            1 *
              (formControlData?.one_one &&
              formControlData?.one_one?.display !== 0
                ? directDataApiData?.one_one
                : 0) +
            0.5 *
              (formControlData?.one_two &&
              formControlData?.one_two?.display !== 0
                ? directDataApiData?.one_two
                : 0) +
            0.33 *
              (formControlData?.one_three &&
              formControlData?.one_three.display !== 0
                ? directDataApiData?.one_three
                : 0) +
            0.25 *
              (formControlData?.one_four &&
              formControlData.one_four.display !== 0
                ? directDataApiData?.one_four
                : 0) +
            0.2 *
              (formControlData?.one_five &&
              formControlData.one_five.display !== 0
                ? directDataApiData?.one_five
                : 0) +
            0.1 *
              (formControlData?.one_six && formControlData.one_six.display !== 0
                ? directDataApiData?.one_six || 0
                : 0) +
            0.2 *
              (formControlData?.observation_patients &&
              formControlData?.observation_patients.display !== 0
                ? directDataApiData?.observation_patients
                : 0) +
            0.2 *
              (formControlData?.inpatient_boarders &&
              formControlData?.inpatient_boarders.display !== 0
                ? directDataApiData?.inpatient_boarders
                : 0);

          const formattedRnTotalSum = rnTotalSum?.toFixed(2);
          setRnTotal(formattedRnTotalSum);

          setFormdata({
            ...formdata,
            ...patientDataObj,
            total_patients: TotalPatientsCount,
          });
        }

        if (title == "AdditionalDataTracking") {
          const finalAd = finalAdditionalData
            .filter(
              (val) =>
                val.field_name !== "comment" &&
                val.field_name !== "submitted_by_text"
            )
            .reduce((obj, val) => {
              obj[val.field_name] = directDataApiData?.[val.field_name] || 0;
              return obj;
            }, {});

          setFormdata({
            ...formdata,
            ...finalAd,
            total_patients: totalPatientvariance,
          });
          setBltChecked(directDataApiData?.did_blt_cover_obs);
        }
      } else {
        let census;

        if (event) {
          if (
            formControlData.total_patients_three &&
            formControlData.total_patients_three.display
          ) {
            if (optional) {
              census = +optional;
            } else {
              census = formdata.total_patients_three
                ? +formdata.total_patients_three
                : 0;
            }
          } else {
            if (sum || sum === 0) {
              census = +sum;
            } else {
              census = +totalPatientvariance;
            }
          }
        } else {
          census = +totalPatientvariance;
        }

        const getgridparams = {
          cost_center_number: cost_center_number,
          shift_time: activedatefilter.shift_time + ":00",
          shift_date: formatDashDate(activedatefilter?.shift_date),
          census: census,
          l_and_d_census: lnCensusGrid,
          nicu_census: nicuCensusGrid,
          pp_census: ppCensusGrid,
        };
        const encryptData = dataEnCrypt(getgridparams);

        let addgriddata = await axios.post(
          API_CALL_URL_PRIVATE + "/department/getGridData",
          { payloadData: encryptData }
        );
        const data = decryptedFunction(addgriddata?.data?.responseData);

        // let addgridresult = await addgriddata;
        const gridresult = data?.data?.gridData;
        setGridData(gridresult);

        let VarianceDataGrid = {};
        let StaffingGridData = {};
        // if Event - Staffing Onchange Calculation
        // if Not Event - Grid Copy Calculation

        if (event) {
          StaffingGridData = finalStaffingData.reduce((obj, val) => {
            let fieldValue =
              (formdata?.[val.field_name] ||
                formdata?.[val.field_name] === 0) &&
              (gridresult?.[
                SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]
              ] ||
                gridresult?.[
                  SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]
                ] === 0)
                ? +formdata?.[val.field_name] -
                  gridresult?.[
                    SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]
                  ]
                : 0;

            obj[val.field_name] = fieldValue;
            return obj;
          }, {});

          const updateFormdata = async () => {
            return new Promise((resolve) => {
              setFormdata((prevFormdata) => {
                const updatedFormdata = {
                  ...prevFormdata,
                  total_patients: sum,
                  [event.target.name]: event.target.value,
                };

                resolve(updatedFormdata);
                return updatedFormdata;
              });
            });
          };

          await updateFormdata();
        } else {
          StaffingGridData = finalStaffingData.reduce((obj, val) => {
            obj[val.field_name] =
              (gridData?.[SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]] ||
                0) -
              (gridData?.[SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]] ||
                0);
            return obj;
          }, {});

          VarianceDataGrid = finalStaffingData.reduce((obj, val) => {
            obj[val.field_name] =
              gridresult?.[
                SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]
              ] || 0;
            return obj;
          }, {});

          setFormdata({
            ...formdata,
            ...VarianceDataGrid,
            total_patients: sum,
          });
        }

        setVariance(StaffingGridData);
        setTotalVar(Object.values(StaffingGridData).reduce((a, b) => a + b, 0));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const modalsshide = () => {
    setFormdata(init);
    setTotalPatientVar(0);
    setVariance({});
    setTotalVar(0);
    reloaddata();
    callbackfunc(false, false, successMsg);
  };

  // Grid Api data override for admin and user
  if (userType !== 0) {
    // for employee
    gridDataApiData = {
      ...gridDataApiData,
      dc_nurse: 0,
      orientation: 0,
      required_charge: 0,
      required_cna: 0,
      required_nurses: 0,
      required_res_brk_rn: 0,
      sitter: 0,
      unit_sec: 0,
      techs_cna: 0,
      psa_si: 0,
      psa_medical: 0,
      blt: 0,
      other: 0,
      soc_worker_ft: 0,
      soc_worker_pt: 0,
      occ_therapist: 0,
      pp_rn: 0,
      l_and_d_rn: 0,
      nicu_peds_rn: 0,
      triage_rn: 0,
      lead_tech: 0,
      clinical_coordinator: 0,
      front_desk: 0,
      ivs: 0,
      ed_reg: 0,
      seven_ave_reg: 0,
      rt: 0,
      support: 0,
      scrub_tech: 0,
      rn_preceptor: 0,
      rn_board_runner: 0,
      scrub_tech_preceptor: 0,
      scrub_tech_board_runner: 0,
      rnfa_stfa: 0,
      rcu_rn: 0,
      lab_technologist: 0,
      phlebotomist_clinical_services: 0,
      education_orientation: 0,
      contract_labor_tech: 0,
      contract_labor_phlebotomy: 0,
      histology: 0,
      client_services: 0,
      receptionist: 0,
      health_plan_coordinator: 0,
      medical_assistant: 0,
      lead_medical_assistant: 0,
      radiologist_technologist: 0,
      athletic_trainer: 0,
    };
  } else {
    // for admin
    // gridDataApiData = {
    //   ...gridDataApiData,
    //   techs_cna: 0,
    // };
  }

  // RN Variance and WIT/RN
  useEffect(() => {
    const witValue = formdata?.wit ? formdata?.wit : 0;
    const rnTotalValue = formdata?.nurses ? formdata?.nurses : 0;
    const rnAcuityNurse = acuityNurse ? acuityNurse : 0;

    const rnVarianceCalculation =
      rnAcuityNurse !== 0
        ? (rnAcuityNurse * rnTotalValue - witValue) / rnAcuityNurse // RN Variance
        : 0;
    setRnVariance(
      isNaN(rnVarianceCalculation) ? 0 : rnVarianceCalculation?.toFixed(2)
    );

    if (isNaN(witValue) || isNaN(rnTotalValue) || rnTotalValue === 0) {
      setWitRn(0);
    } else {
      const witRnDivider = witValue / rnTotalValue; // WIT/RN
      if (isFinite(witRnDivider)) {
        const roundedValue = +witRnDivider.toFixed(2);
        setWitRn(roundedValue);
      } else {
        setWitRn(0);
      }
    }
  }, [formdata?.wit, formdata?.nurses]);

  // L&D RN Variance
  useEffect(() => {
    const labourPatientValue =
      formControlData?.labor_patients_recovery?.display !== 0 &&
      formdata?.labor_patients_recovery
        ? formdata?.labor_patients_recovery
        : 0;
    const ldRnValue =
      formControlData?.l_and_d_rn?.display !== 0 && formdata?.l_and_d_rn
        ? formdata?.l_and_d_rn
        : 0;

    const ldRnMulti = 1 * labourPatientValue;
    const ldRnTotalVariance = ldRnValue - ldRnMulti;
    if (ldRnTotalVariance !== 0) {
      setLdRnVariance(ldRnTotalVariance?.toFixed(0));
    } else {
      setLdRnVariance(0);
    }
  }, [
    formdata?.labor_patients_recovery,
    formdata?.l_and_d_rn,
    formdata?.l_and_d_rn_variance,
  ]);

  // NICU Variance
  useEffect(() => {
    const nicuPedsValue =
      formControlData?.nicu_peds_rn?.display !== 0 && formdata?.nicu_peds_rn
        ? formdata?.nicu_peds_rn
        : 0;
    const nicuFeederGrowerValue =
      formControlData?.nicu_feeder_growers?.display !== 0 &&
      formdata?.nicu_feeder_growers
        ? formdata?.nicu_feeder_growers
        : 0;
    const nicuCpapValue =
      formControlData?.nicu_cpap?.display !== 0 && formdata?.nicu_cpap
        ? formdata?.nicu_cpap
        : 0;
    const multiplierOneTwo = 0.5;
    const multiplierOneThree = 0.333;
    const nicuRnVariance =
      nicuPedsValue -
      (nicuFeederGrowerValue * multiplierOneThree +
        nicuCpapValue * multiplierOneTwo);
    if (nicuRnVariance !== 0) {
      setNicuVariance(nicuRnVariance?.toFixed(3));
    } else {
      setNicuVariance(0);
    }
  }, [
    formdata?.nicu_peds_rn,
    formdata?.nicu_feeder_growers,
    formdata?.nicu_cpap,
  ]);

  // PP RN Variance
  useEffect(() => {
    const ppRnValue =
      formControlData?.pp_rn?.display !== 0 && formdata?.pp_rn
        ? formdata?.pp_rn
        : 0;
    const postOpValue =
      formControlData?.post_op_pp_one_two?.display !== 0 &&
      formdata?.post_op_pp_one_two
        ? formdata?.post_op_pp_one_two
        : 0;
    const stableppValue =
      formControlData?.stable_pp_one_three?.display !== 0 &&
      formdata?.stable_pp_one_three
        ? formdata?.stable_pp_one_three
        : 0;
    const multiplierOneTwo = 0.5;
    const multiplierOneThree = 0.333;
    const nicuRnVariance =
      ppRnValue -
      (postOpValue * multiplierOneTwo + stableppValue * multiplierOneThree);
    if (nicuRnVariance !== 0) {
      setppRnVariance(nicuRnVariance?.toFixed(3));
    } else {
      setppRnVariance(0);
    }
  }, [
    formdata?.pp_rn,
    formdata?.post_op_pp_one_two,
    formdata?.stable_pp_one_three,
  ]);

  // Custom Rn Variance
  useEffect(() => {
    const nursesValue =
      formControlData?.nurses?.display !== 0 && formdata?.nurses
        ? formdata?.nurses
        : 0;
    const standardUnitValue = formContent?.standard_unit_ratio || 0;
    const censusValue =
      formControlData?.total_patients_three?.display !== 0
        ? formdata?.total_patients_three
        : formdata?.total_patients;
    const unitDivider =
      standardUnitValue !== 0 ? censusValue / standardUnitValue : 0;
    const unitTotal = isNaN(unitDivider) ? 0 : unitDivider;
    const customRnValue = nursesValue - unitTotal;
    if (customRnValue !== 0) {
      setCustomRnVariance(customRnValue?.toFixed(1));
    } else {
      setCustomRnVariance(0);
    }
  }, [
    formdata?.nurses,
    formdata?.total_patients,
    formdata?.total_patients_three,
  ]);

  // Calculated Unit Service
  useEffect(() => {
    const ptScheduledValue =
      formControlData?.pts_scheduled?.display !== 0 && formdata?.pts_scheduled
        ? formdata?.pts_scheduled
        : 0;
    setUnitServiceCal(ptScheduledValue);
  }, [formdata?.pts_scheduled]);

  // Calculated Procedures 1
  useEffect(() => {
    const EchoValue =
      formControlData?.echo?.display !== 0 && formdata?.echo
        ? formdata?.echo
        : 0;
    const DobutamineValue =
      formControlData?.dobutamine_stress_echo?.display !== 0 &&
      formdata?.dobutamine_stress_echo
        ? formdata?.dobutamine_stress_echo
        : 0;
    const StressValue =
      formControlData?.stress_echo?.display !== 0 && formdata?.stress_echo
        ? formdata?.stress_echo
        : 0;
    const TransesoValue =
      formControlData?.transesopageal_echo?.display !== 0 &&
      formdata?.transesopageal_echo
        ? formdata?.transesopageal_echo
        : 0;
    const ofCardiacValue =
      formControlData?.of_cardiac_procedures?.display !== 0 &&
      formdata?.of_cardiac_procedures
        ? formdata?.of_cardiac_procedures
        : 0;
    const iolPainValue =
      formControlData?.iol_pain_local_cases?.display !== 0 &&
      formdata?.iol_pain_local_cases
        ? formdata?.iol_pain_local_cases
        : 0;
    const painMangValue =
      formControlData?.pain_management?.display !== 0 &&
      formdata?.pain_management
        ? formdata?.pain_management
        : 0;
    const totalProcedure1 =
      Number(EchoValue) +
      Number(DobutamineValue) +
      Number(StressValue) +
      Number(TransesoValue) +
      Number(ofCardiacValue) +
      Number(iolPainValue) +
      Number(painMangValue);

    if (totalProcedure1 !== 0) {
      setProcedureOneCal(totalProcedure1?.toFixed(2));
    } else {
      setProcedureOneCal(0);
    }
  }, [
    formdata?.echo,
    formdata?.dobutamine_stress_echo,
    formdata?.stress_echo,
    formdata?.transesopageal_echo,
    formdata?.of_cardiac_procedures,
    formdata?.iol_pain_local_cases,
    formdata?.pain_management,
  ]);

  // Calculated Procedures 2
  useEffect(() => {
    const giValue =
      formControlData?.gi?.display !== 0 && formdata?.gi ? formdata?.gi : 0;
    const ErcpValue =
      formControlData?.ercp?.display !== 0 && formdata?.ercp
        ? formdata?.ercp
        : 0;
    const BronchoValue =
      formControlData?.bronchoscopy?.display !== 0 && formdata?.bronchoscopy
        ? formdata?.bronchoscopy
        : 0;
    const totalProcedure2 =
      Number(giValue) + Number(ErcpValue) + Number(BronchoValue);
    if (totalProcedure2 !== 0) {
      setProcedureTwoCal(totalProcedure2?.toFixed(2));
    } else {
      setProcedureTwoCal(0);
    }
  }, [formdata?.gi, formdata?.ercp, formdata?.bronchoscopy]);

  // Calculated Items
  useEffect(() => {
    const itemValue =
      formControlData?.items?.display !== 0 && formdata?.items
        ? formdata?.items
        : 0;
    setItemsCal(Number(itemValue));
  }, [formdata?.items]);

  // Calculated  Minutes
  useEffect(() => {
    const actualMinuteValue =
      formControlData?.actual_minutes?.display !== 0 && formdata?.actual_minutes
        ? formdata?.actual_minutes
        : 0;
    const totalMinutes = Number(actualMinuteValue) * 0.83;
    if (totalMinutes !== 0) {
      setMinutesCal(totalMinutes?.toFixed(2));
    } else {
      setMinutesCal(0);
    }
  }, [formdata?.actual_minutes]);

  // Calculated  Total Registrations
  useEffect(() => {
    const frontDeskReg =
      formControlData?.front_desk_reg?.display !== 0 && formdata?.front_desk_reg
        ? formdata?.front_desk_reg
        : 0;
    const sevenAveReg =
      formControlData?.seven_ave_reg_patient?.display !== 0 &&
      formdata?.seven_ave_reg_patient
        ? formdata?.seven_ave_reg_patient
        : 0;
    const edReg =
      formControlData?.ed_reg_patient?.display !== 0 && formdata?.ed_reg_patient
        ? formdata?.ed_reg_patient
        : 0;
    const totalRegistration =
      Number(frontDeskReg) + Number(sevenAveReg) + Number(edReg);

    if (totalRegistration !== 0) {
      setTotalRegCal(totalRegistration?.toFixed(2));
    } else {
      setTotalRegCal(0);
    }
  }, [
    formdata?.front_desk_reg,
    formdata?.seven_ave_reg_patient,
    formdata?.ed_reg_patient,
  ]);

  // Calculated  In-Clinic Visits
  useEffect(() => {
    const levelVisitOne =
      formControlData?.level_1_visits?.display !== 0 && formdata?.level_1_visits
        ? formdata?.level_1_visits
        : 0;
    const levelVisitTwo =
      formControlData?.level_2_visits?.display !== 0 && formdata?.level_2_visits
        ? formdata?.level_2_visits
        : 0;
    const levelVisitThree =
      formControlData?.level_3_visits?.display !== 0 && formdata?.level_3_visits
        ? formdata?.level_3_visits
        : 0;
    const levelVisitFour =
      formControlData?.level_4_visits?.display !== 0 && formdata?.level_4_visits
        ? formdata?.level_4_visits
        : 0;

    const levelVisitFive =
      formControlData?.level_5_visits?.display !== 0 && formdata?.level_5_visits
        ? formdata?.level_5_visits
        : 0;

    const totalClinicVisit =
      Number(levelVisitOne) +
      Number(levelVisitTwo) +
      Number(levelVisitThree) +
      Number(levelVisitFour) +
      Number(levelVisitFive);

    if (totalClinicVisit !== 0) {
      setClinicVisitCal(totalClinicVisit?.toFixed(2));
    } else {
      setClinicVisitCal(0);
    }
  }, [
    formdata?.level_1_visits,
    formdata?.level_2_visits,
    formdata?.level_3_visits,
    formdata?.level_4_visits,
    formdata?.level_5_visits,
  ]);

  // Calculated  IOL, Mac, Pacu Minutes
  useEffect(() => {
    const iolPlainCases =
      formControlData?.iol_pain_local_cases?.display !== 0 &&
      formdata?.iol_pain_local_cases
        ? formdata?.iol_pain_local_cases
        : 0;
    const MaCases =
      formControlData?.mac_cases?.display !== 0 && formdata?.mac_cases
        ? formdata?.mac_cases
        : 0;
    const pacuCases =
      formControlData?.pacu_cases?.display !== 0 && formdata?.pacu_cases
        ? formdata?.pacu_cases
        : 0;

    const totalIolMacu =
      Number(iolPlainCases) * 30 +
      Number(MaCases) * 60 +
      Number(pacuCases) * 45;

    if (totalIolMacu !== 0) {
      setIolMacPacuCal(totalIolMacu?.toFixed(2));
    } else {
      setIolMacPacuCal(0);
    }
  }, [
    formdata?.iol_pain_local_cases,
    formdata?.mac_cases,
    formdata?.pacu_cases,
  ]);

  // Calculated  Of Minutes
  useEffect(() => {
    const ofCasesValue =
      formControlData?.of_cases?.display !== 0 && formdata?.of_cases
        ? formdata?.of_cases
        : 0;
    const totalOfMinutes = 143 * Number(ofCasesValue);
    if (totalOfMinutes !== 0) {
      setOfMinutesCal(totalOfMinutes?.toFixed(2));
    } else {
      setOfMinutesCal(0);
    }
  }, [formdata?.of_cases]);

  // // L&D Census that drives L&D RN grid
  // useEffect(() => {
  //   const labourPatientValue =
  //     formControlData?.labor_patients_recovery?.display !== 0 &&
  //     formdata?.labor_patients_recovery
  //       ? formdata?.labor_patients_recovery
  //       : 0;

  //   const lnTotal = Number(labourPatientValue);
  //   setlnCensusGrid(lnTotal);
  //   getGridData(lnTotal, nicuCensusGrid, ppCensusGrid);
  // }, [formdata?.labor_patients_recovery]);

  // // NICU census that drives NICU Peds / RN grid
  // useEffect(() => {
  //   const nicuCpapValue =
  //     formControlData?.nicu_cpap?.display !== 0 && formdata?.nicu_cpap
  //       ? formdata?.nicu_cpap
  //       : 0;

  //   const nicuFeederValue =
  //     formControlData?.nicu_feeder_growers?.display !== 0 &&
  //     formdata?.nicu_feeder_growers
  //       ? formdata?.nicu_feeder_growers
  //       : 0;

  //   const nicuTotal = Number(nicuCpapValue) + Number(nicuFeederValue);
  //   setNicuCensusGrid(nicuTotal);
  //   getGridData(lnCensusGrid, nicuTotal, ppCensusGrid);
  // }, [formdata?.nicu_cpap, formdata?.nicu_feeder_growers]);

  // // PP Census that drives PP RN grid
  // useEffect(() => {
  //    const postOpValue =
  //      formControlData?.post_op_pp_one_two?.display !== 0 &&
  //      formdata?.post_op_pp_one_two
  //        ? Math.round(formdata?.post_op_pp_one_two / 2)
  //        : 0;

  //   const stableppValue =
  //     formControlData?.stable_pp_one_three?.display !== 0 &&
  //     formdata?.stable_pp_one_three
  //       ? Math.round(formdata?.stable_pp_one_three / 3)
  //       : 0;

  //   const totalPpCensus = Number(postOpValue) + Number(stableppValue);
  //   setPpCensusGrid(totalPpCensus);
  //   getGridData(lnCensusGrid, nicuCensusGrid, totalPpCensus);
  // }, [formdata?.post_op_pp_one_two, formdata?.stable_pp_one_three]);

  const getGridData = async (
    lnCensus,
    nicuCensus,
    ppCensus,
    patientSum,
    event
  ) => {
    try {
      let census;
      if (
        formControlData.total_patients_three &&
        formControlData.total_patients_three.display
      ) {
        census = formdata.total_patients_three
          ? +formdata.total_patients_three
          : 0;
      } else {
        census = patientSum ? +patientSum : 0;
      }

      const getgridparams = {
        cost_center_number: cost_center_number,
        shift_time: activedatefilter.shift_time + ":00",
        census: census,
        shift_date: formatDashDate(activedatefilter?.shift_date),
        l_and_d_census: lnCensus,
        nicu_census: nicuCensus,
        pp_census: ppCensus,
      };
      const encryptData = dataEnCrypt(getgridparams);

      let addgriddata = await axios.post(
        API_CALL_URL_PRIVATE + "/department/getGridData",
        { payloadData: encryptData }
      );
      const data = decryptedFunction(addgriddata?.data?.responseData);
      // let addgridresult = await addgriddata;
      const gridresult = data?.data?.gridData;
      setGridData(gridresult);

      let StaffingGridData = finalStaffingData.reduce((obj, val) => {
        let fieldValue =
          (formdata?.[val.field_name] || formdata?.[val.field_name] === 0) &&
          (gridresult?.[SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]] ||
            gridresult?.[SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]] ===
              0)
            ? +formdata?.[val.field_name] -
              gridresult?.[SHIFT_FORM?.STAFFING_GRID_VALUE?.[val?.field_name]]
            : 0;

        obj[val.field_name] = fieldValue;
        return obj;
      }, {});

      const updateFormdata = async () => {
        return new Promise((resolve) => {
          setFormdata((prevFormdata) => {
            const updatedFormdata = {
              ...prevFormdata,
              total_patients: patientSum,
              [event.target.name]: event.target.value,
            };

            resolve(updatedFormdata);
            return updatedFormdata;
          });
        });
      };
      await updateFormdata();

      setVariance(StaffingGridData);
      setTotalVar(Object.values(StaffingGridData).reduce((a, b) => a + b, 0));
    } catch (error) {
      console.log(error);
    }
  };

  // HANDLE DID BLT TOGGLE
  const handleBltChecked = () => {
    setBltChecked((prevState) => !prevState);
    setFormdata((prevData) => ({
      ...prevData,
      did_blt_cover_obs: !bltChecked ? 1 : 0,
    }));
  };

  const varianceTrackingState = {
    rn_available_hours: rnTotal,
    wit_rn: witRn,
    rn_variance: rnVariance,
    l_and_d_rn_variance: ldRnVariance,
    nicu_variance: nicuVariance,
    pp_rn_variance: ppRnVariance,
    custom_rn_variance: customRnVariance,
    calculated_units_of_service: unitServiceCal,
    calculated_procedures_1: procedureOneCal,
    calculated_procedures_2: procedureTwoCal,
    calculated_items: itemsCal,
    calculated_minutes: minutesCal,
    calculated_total_registrations: totalRegCal,
    in_clinic_visits: clinicVisitCal,
    iol_mac_pacu_minutes: iolMacPacuCal,
    of_minutes: ofMinutesCal,
    populated_minutes_1: periopState?.populated_minutes_1,
    populated_minutes_2: periopState?.populated_minutes_2,
    populated_minutes_3: periopState?.populated_minutes_3,
    populated_minutes_4: periopState?.populated_minutes_4,
  };

  const checkAdditionalPrevious = (() => {
    const fieldNames = finalAdditionalData?.map((item) => item?.field_name);

    if (finalAdditionalData?.length === 2) {
      return (
        fieldNames?.includes("comment") &&
        fieldNames?.includes("submitted_by_text")
      );
    } else if (finalAdditionalData?.length === 1) {
      return (
        fieldNames?.includes("comment") ||
        fieldNames.includes("submitted_by_text")
      );
    } else if (finalAdditionalData?.length === 0) {
      return true;
    }

    return false;
  })();

  return (
    <Modal show={isShow} onHide={modalsshide} centered>
      <div className=" modal-dialog-centered modal-lg">
        <div className="modal-content pb-4">
          <div className="modal-header border-0 pb-3 justify-content-center position-relative pe-5">
            <h5 className="modal-title fw-400">
              SUBMITTING - {cost_center_name?.toUpperCase()}&nbsp;-&nbsp;
              <span className="">
                {activedatefilter?.shift_date}{" "}
                {costCenterData?.interval !== 24 &&
                  activedatefilter?.shift_time}{" "}
                INTERVAL
              </span>
            </h5>
            <button
              type="button"
              onClick={modalsshide}
              className="btn-close ms-5 position-absolute right-20 bg-transparent"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body inputnumbericonhide py-0 para-5">
            <div className="row">
              <div className="col-md-3 border-end w1">
                <div className=" text-center">
                  <h6 className="fw-400 fw lh-1">
                    {formShiftLabel?.patient_data_label
                      ? formShiftLabel?.patient_data_label
                      : "Patient Data"}
                  </h6>
                </div>
                <div className="min-height-400">
                  <table className="w-100 mb-2 m-g-2">
                    <tbody>
                      <tr className="hd">
                        <th></th>
                        <th></th>
                        <th>{userType === 0 ? "Previous" : ""}</th>
                        <th>
                          {costCenterData?.interval !== 24 &&
                            activedatefilter?.shift_time}
                        </th>{" "}
                      </tr>
                      {finalPatientData?.map((val, ind) => {
                        const varianceRowFields = [
                          "rn_available_hours",
                          "wit_rn",
                          "rn_variance",
                          "l_and_d_rn_variance",
                          "nicu_variance",
                          "pp_rn_variance",
                          "custom_rn_variance",
                          "calculated_units_of_service",
                          "calculated_procedures_1",
                          "calculated_procedures_2",
                          "calculated_items",
                          "calculated_minutes",
                          "calculated_total_registrations",
                          "in_clinic_visits",
                          "iol_mac_pacu_minutes",
                          "of_minutes",
                          "populated_minutes_1",
                          "populated_minutes_2",
                          "populated_minutes_3",
                          "populated_minutes_4",
                        ];
                        const varianceRenderRow = varianceRowFields.includes(
                          val.field_name
                        )
                          ? val.field_name
                          : null;

                        return (
                          <tr key={ind}>
                            <>
                              {val.field_name === "total_patients" ? (
                                <>
                                  <td className="t">{val?.field_label}</td>
                                  <td class="img">
                                    {process.env.React_App_ENVIRONMENT ===
                                    "development" ? (
                                      <img
                                        src={
                                          themeMode.theme === "DARK"
                                            ? SHIFT_FORM
                                                .DEV_PATIENT_ICON_DARK?.[
                                                val?.field_name
                                              ]
                                            : SHIFT_FORM
                                                .DEV_PATIENT_ICON_LIGHT?.[
                                                val?.field_name
                                              ]
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src={
                                          themeMode.theme === "DARK"
                                            ? SHIFT_FORM.PATIENT_ICON_DARK?.[
                                                val?.field_name
                                              ]
                                            : SHIFT_FORM.PATIENT_ICON_LIGHT?.[
                                                val?.field_name
                                              ]
                                        }
                                        alt=""
                                      />
                                    )}
                                  </td>
                                  <td></td>
                                  <td>
                                    <hr className="totalline my-1" />
                                    <div
                                      type="button"
                                      className={`tooltip left-tool position-relative ${
                                        totalPatientvariance < 0
                                          ? "textdanger"
                                          : ""
                                      }`}
                                    >
                                      {formatDecimalNumber(
                                        totalPatientvariance
                                      )}
                                      <span class="tooltiptext">Total</span>
                                    </div>
                                  </td>
                                </>
                              ) : val.field_name === varianceRenderRow ? (
                                <>
                                  <td className="t">{val?.field_label}</td>
                                  <td className="img">
                                    {process.env.React_App_ENVIRONMENT ===
                                    "development" ? (
                                      <img
                                        src={
                                          themeMode.theme === "DARK"
                                            ? SHIFT_FORM
                                                .DEV_PATIENT_ICON_DARK?.[
                                                val?.field_name
                                              ]
                                            : SHIFT_FORM
                                                .DEV_PATIENT_ICON_LIGHT?.[
                                                val?.field_name
                                              ]
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src={
                                          themeMode.theme === "DARK"
                                            ? SHIFT_FORM.PATIENT_ICON_DARK?.[
                                                val?.field_name
                                              ]
                                            : SHIFT_FORM.PATIENT_ICON_LIGHT?.[
                                                val?.field_name
                                              ]
                                        }
                                        alt=""
                                      />
                                    )}
                                  </td>
                                  <td></td>
                                  <td>
                                    <input
                                      className={`form-control ${
                                        formdata?.[val?.field_name] === "" &&
                                        val?.required === 1
                                          ? "redborder"
                                          : ""
                                      }`}
                                      name={val?.field_name}
                                      value={
                                        varianceTrackingState[val?.field_name]
                                      }
                                      type="number"
                                      onChange={handleChange}
                                      disabled
                                      aria-label=" input example"
                                    />
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="t">{val?.field_label}</td>
                                  <td className="img">
                                    {process.env.React_App_ENVIRONMENT ===
                                    "development" ? (
                                      <img
                                        src={
                                          themeMode.theme === "DARK"
                                            ? SHIFT_FORM
                                                .DEV_PATIENT_ICON_DARK?.[
                                                val?.field_name
                                              ]
                                            : SHIFT_FORM
                                                .DEV_PATIENT_ICON_LIGHT?.[
                                                val?.field_name
                                              ]
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src={
                                          themeMode.theme === "DARK"
                                            ? SHIFT_FORM.PATIENT_ICON_DARK?.[
                                                val?.field_name
                                              ]
                                            : SHIFT_FORM.PATIENT_ICON_LIGHT?.[
                                                val?.field_name
                                              ]
                                        }
                                        alt=""
                                      />
                                    )}
                                  </td>
                                  <td>
                                    {userType === 0 && (
                                      <input
                                        className="form-control"
                                        name={val?.field_name}
                                        value={
                                          directDataApiData?.[
                                            val?.field_name
                                          ] || 0
                                        }
                                        type="text"
                                        disabled
                                        aria-label=" input example"
                                        readOnly
                                      />
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      className={`form-control ${
                                        formdata?.[val?.field_name] === "" &&
                                        val?.required === 1
                                          ? "redborder"
                                          : ""
                                      }`}
                                      name={val?.field_name}
                                      value={
                                        formdata?.[val?.field_name] !== null
                                          ? formdata[val?.field_name]
                                          : ""
                                      }
                                      type="number"
                                      onChange={handleChange}
                                      aria-label=" input example"
                                    />
                                  </td>
                                </>
                              )}
                            </>
                          </tr>
                        );
                      })}
                      <tr>
                        <td className="t"></td>
                        <td></td>
                        <td className="pt-2">
                          {userType === 0 && !isEmpty(finalPatientData) && (
                            <button
                              type="button"
                              className="ms-auto btn grey-btn arrow-btn text-capitalize tooltip left-tool position-relative"
                            >
                              <div
                                onClick={() => {
                                  directDataApiData?._id &&
                                    getDirectDataById("PatientData");
                                }}
                                className="arrowicon"
                              >
                                <img src={RightIcon} alt="" />
                              </div>
                              <span className="tooltiptext">
                                {directDataApiData?._id
                                  ? "Fill from previous shift?"
                                  : "No Data In Previous Shift"}
                              </span>
                            </button>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {costCenterData?.interval === 24 &&
                  !!activedatefilter?.display_planned_hpous && (
                    <div>
                      <table className="w-100 mb-2 m-g-2">
                        <tbody>
                          <tr>
                            <>
                              <td className="t w-50">Planned HPUOS</td>
                              <td className="img "></td>
                              <td className="w-25"></td>
                              <td className="tdx">
                                <input
                                  className="form-control"
                                  // name={val?.field_name}
                                  value={plannedHpous}
                                  type="number"
                                  // onChange={handleChange}
                                  disabled
                                  aria-label=" input example"
                                />
                              </td>
                            </>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
              </div>
              <div className="col-md-5 w2">
                <div className=" text-center">
                  <h6 className="fw-400 fw lh-1">
                    {formShiftLabel?.staffing_data_label
                      ? formShiftLabel?.staffing_data_label
                      : "Staffing"}
                  </h6>
                </div>
                <table className="w-100 mb-2 m-g-2">
                  <tbody>
                    <tr className="hd">
                      <th></th>
                      <th></th>
                      <th>{userType === 0 ? "Previous" : ""}</th>
                      <th>
                        {costCenterData?.interval !== 24 &&
                          activedatefilter?.shift_time}
                      </th>{" "}
                      <th>Grid</th>
                      <th>Variance</th>
                    </tr>
                    {finalStaffingData?.map((val, ind) => {
                      return (
                        <tr key={ind}>
                          <>
                            <td className="t">{val?.field_label}</td>
                            <td class="img">
                              {process.env.React_App_ENVIRONMENT ===
                              "development" ? (
                                <img
                                  src={
                                    themeMode.theme === "DARK"
                                      ? SHIFT_FORM.DEV_STAFFING_ICON_DARK?.[
                                          val?.field_name
                                        ]
                                      : SHIFT_FORM.DEV_STAFFING_ICON_LIGHT?.[
                                          val?.field_name
                                        ]
                                  }
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={
                                    themeMode.theme === "DARK"
                                      ? SHIFT_FORM.STAFFING_ICON_DARK?.[
                                          val?.field_name
                                        ]
                                      : SHIFT_FORM.STAFFING_ICON_LIGHT?.[
                                          val?.field_name
                                        ]
                                  }
                                  alt=""
                                />
                              )}
                            </td>
                            <td>
                              {userType === 0 && (
                                <input
                                  className="form-control"
                                  type="text"
                                  value={
                                    directDataApiData?.[val?.field_name] || 0
                                  }
                                  disabled
                                  aria-label=" input example"
                                  readonly
                                />
                              )}
                            </td>
                            <td>
                              <input
                                className={`form-control ${
                                  formdata?.[val?.field_name] === "" &&
                                  val?.required === 1
                                    ? "redborder"
                                    : ""
                                }`}
                                name={val?.field_name}
                                value={
                                  formdata?.[val?.field_name] != null
                                    ? formdata?.[val?.field_name]
                                    : ""
                                }
                                type="number"
                                onChange={handleChange}
                                aria-label=" input example"
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={
                                  gridData?.[
                                    SHIFT_FORM?.STAFFING_GRID_VALUE?.[
                                      val?.field_name
                                    ]
                                  ] || 0
                                }
                                disabled
                                aria-label=" input example"
                                readonly
                              />
                            </td>
                            <td>
                              <div
                                type="button"
                                class="tooltip left-tool position-relative"
                              >
                                <input
                                  className={`form-control ${
                                    variance?.[val?.field_name] > 0
                                      ? "red-text"
                                      : ""
                                  }`}
                                  type="text"
                                  value={formatDecimalNumber(
                                    variance?.[val?.field_name]
                                  )}
                                  disabled
                                  aria-label=" input example"
                                  readonly
                                />
                                <span class="tooltiptext">
                                  {val?.field_label}
                                </span>
                              </div>
                            </td>
                          </>
                        </tr>
                      );
                    })}
                    {!isEmpty(finalStaffingData) && (
                      <tr>
                        <td className="t"></td>
                        <td></td>
                        <td className="pt-2">
                          {userType === 0 && (
                            <button
                              type="button"
                              className="ms-auto btn grey-btn arrow-btn text-capitalize tooltip left-tool position-relative"
                            >
                              <div
                                onClick={() => {
                                  directDataApiData?._id &&
                                    getDirectDataById("Staffing");
                                }}
                                className="arrowicon"
                              >
                                <img src={RightIcon} alt="" />
                              </div>
                              <span className="tooltiptext">
                                {directDataApiData?._id
                                  ? "Fill from previous shift?"
                                  : "No Data In Previous Shift"}
                              </span>
                            </button>
                          )}
                        </td>
                        <td></td>
                        <td className="pt-2">
                          {/* {totalPatientvariance > 0 ? ( */}
                          {userType === 0 && totalPatientvariance > 0 ? (
                            <button
                              type="button"
                              className="ms-auto btn grey-btn arrow-btn text-capitalize tooltip left-tool position-relative"
                            >
                              <div
                                onClick={() => {
                                  directDataApiData?._id &&
                                    getDirectDataById("Grid");
                                }}
                                className="arrowicon"
                              >
                                <img src={LeftIcon} alt="" />
                              </div>
                              <span className="tooltiptext">
                                Use Grid for Actuals?
                              </span>
                            </button>
                          ) : null}
                        </td>
                        <td>
                          <hr className="totalline my-1" />
                          <div
                            type="button"
                            className={`tooltip left-tool position-relative ${
                              totalvariance > 0 ? "textdanger" : ""
                            }`}
                          >
                            {formatDecimalNumber(totalvariance)}
                            <span class="tooltiptext">Total</span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-4 border-start">
                <div className=" text-center">
                  <h6 className="fw-400 fw lh-1">
                    {formShiftLabel?.additional_data_label
                      ? formShiftLabel?.additional_data_label
                      : "ADDITIONAL DATA TRACKING"}
                  </h6>
                </div>
                <div className="height-417">
                  <table className="w-100 mb-2 m-g-2">
                    <tbody>
                      <tr className="hd">
                        <th></th>
                        <th></th>
                        <th>{userType === 0 ? "Previous" : ""}</th>
                        <th>
                          {costCenterData?.interval !== 24 &&
                            activedatefilter?.shift_time}
                        </th>{" "}
                      </tr>
                      {finalAdditionalData?.map((val, ind) => {
                        if (
                          val.field_name === "comment" ||
                          val.field_name === "submitted_by_text"
                        ) {
                          return null;
                        }
                        return (
                          <tr key={ind}>
                            <>
                              {val.field_name === "did_blt_cover_obs" ? (
                                <>
                                  <td className="t">{val?.field_label}</td>
                                  <td class="img">
                                    <img
                                      src={
                                        themeMode.theme === "DARK"
                                          ? SHIFT_FORM.ADDITIONAL_ICON_DARK?.[
                                              val?.field_name
                                            ]
                                          : SHIFT_FORM.ADDITIONAL_ICON_LIGHT?.[
                                              val?.field_name
                                            ]
                                      }
                                      alt=""
                                    />
                                  </td>
                                  <td>
                                    {userType === 0 && (
                                      <div class="button r" id="button-1">
                                        <input
                                          type="checkbox"
                                          class="checkbox"
                                          checked={
                                            directDataApiData?.did_blt_cover_obs
                                          }
                                          disabled
                                        />
                                        <div class="knobs"></div>
                                        <div class="layer"></div>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <div class="button r" id="button-1">
                                      <input
                                        type="checkbox"
                                        class="checkbox"
                                        onChange={handleBltChecked}
                                        checked={formdata?.did_blt_cover_obs}
                                      />
                                      <div class="knobs"></div>
                                      <div class="layer"></div>
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="t">{val?.field_label}</td>
                                  <td class="img">
                                    {process.env.React_App_ENVIRONMENT ===
                                    "development" ? (
                                      <img
                                        src={
                                          themeMode.theme === "DARK"
                                            ? SHIFT_FORM
                                                .DEV_ADDITIONAL_ICON_DARK?.[
                                                val?.field_name
                                              ]
                                            : SHIFT_FORM
                                                .DEV_ADDITIONAL_ICON_LIGHT?.[
                                                val?.field_name
                                              ]
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src={
                                          themeMode.theme === "DARK"
                                            ? SHIFT_FORM.ADDITIONAL_ICON_DARK?.[
                                                val?.field_name
                                              ]
                                            : SHIFT_FORM
                                                .ADDITIONAL_ICON_LIGHT?.[
                                                val?.field_name
                                              ]
                                        }
                                        alt=""
                                      />
                                    )}
                                  </td>
                                  <td>
                                    {userType === 0 && (
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={
                                          directDataApiData?.[
                                            val?.field_name
                                          ] || 0
                                        }
                                        disabled
                                        aria-label=" input example"
                                        readonly
                                      />
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      className={`form-control ${
                                        formdata?.[val?.field_name] === "" &&
                                        val?.required === 1
                                          ? "redborder"
                                          : ""
                                      }`}
                                      type="number"
                                      onChange={handleChange}
                                      name={val?.field_name}
                                      value={
                                        formdata?.[val?.field_name] !== null
                                          ? formdata[val?.field_name]
                                          : ""
                                      }
                                      aria-label=" input example"
                                    />
                                  </td>
                                </>
                              )}
                            </>
                          </tr>
                        );
                      })}

                      <tr>
                        <td className="t"></td>
                        <td></td>
                        <td className="pt-2">
                          {userType === 0 && !checkAdditionalPrevious && (
                            <button
                              type="button"
                              className="ms-auto btn grey-btn arrow-btn text-capitalize tooltip left-tool position-relative"
                            >
                              <div
                                onClick={() => {
                                  directDataApiData?._id &&
                                    getDirectDataById("AdditionalDataTracking");
                                }}
                                className="arrowicon"
                              >
                                <img src={RightIcon} alt="" />
                              </div>
                              <span className="tooltiptext">
                                {directDataApiData?._id
                                  ? "Fill from previous shift?"
                                  : "No Data In Previous Shift"}
                              </span>
                            </button>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="row mb-3">
                    {formControlData?.submitted_by_text &&
                      formControlData?.submitted_by_text?.display !== 0 && (
                        <div className="col-md-12">
                          <div className="m-f14">
                            {formControlData?.submitted_by_text?.field_label}
                          </div>
                          <div className="mt-1 w-100-input">
                            <input
                              className={`w-100 add-input-field ${
                                formdata?.submitted_by_text === "" &&
                                formControlData?.submitted_by_text?.required ===
                                  1
                                  ? "redborder"
                                  : ""
                              }`}
                              onChange={handleChange}
                              name="submitted_by_text"
                              value={
                                formdata?.submitted_by_text != null
                                  ? formdata?.submitted_by_text
                                  : ""
                              }
                              placeholder="Type Here..."
                            />
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="row">
                    {formControlData?.comment &&
                      formControlData?.comment?.display !== 0 && (
                        <div className="col-md-12">
                          <div className="m-f14">
                            {formControlData?.comment?.field_label}
                          </div>
                          <div className="mt-1">
                            <textarea
                              className="form-control full"
                              onChange={handleChange}
                              name="comment"
                              value={
                                formdata?.comment != null
                                  ? formdata?.comment
                                  : ""
                              }
                              placeholder="Type Here..."
                              style={{ height: "70px" }}
                            ></textarea>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <button
                      type="button"
                      onClick={modalsshide}
                      className="btn btn-red text-capitalize  f-12 fw-400 w-100"
                    >
                      {" "}
                      Cancel
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      onClick={submitHandle}
                      className="btn btn-success text-capitalize  f-12 fw-400 w-100"
                    >
                      Submit{" "}
                    </button>
                  </div>
                </div>
                {isKeyEmpty && (
                  <div className="real-validation m-f14 mt-2">{isKeyEmpty}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        isShow={showError}
        title="Error"
        content={successMsg}
        callbackfunc={() => setShowError(false)}
      />
    </Modal>
  );
}

export default AddModal;
