import styled from "styled-components";

export const LinkBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`
export const LinkTag = styled.a`
    background-color: #6da22f;
    padding: 8px;
    border-radius: 5px;
    color: white;
    margin-top: 0.9rem;
    margin-left: 1rem;
    border: 1px solid transparent;
    
    :hover {
        color: #6da22f;
        background-color: transparent;
        border: 1px solid #6da22f;
    }
`

export const CSVBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 1rem;
`;

  export const CsvTag = styled.a`
    background-color: #6da22f;
    padding: 6px;
    border-radius: 5px;
    color: white;
    border: 1px solid transparent;

    :hover {
      color: #6da22f;
      background-color: transparent;
      border: 1px solid #6da22f;
    }
  `;