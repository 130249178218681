import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import React, { useState } from 'react';
import './index.css';
import 'font-awesome/css/font-awesome.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function DateFilter({ setSliderFilterData, isactivefilter, activetab, active, seCsvUpdateEvent, cost_center_number,fromdate }) {
    const time =  fromdate ? new Date(fromdate) : ""
    const [startDate, setStartDate] = useState("");
    const [InputRangeValue, setInputRange] = useState({ min: 0, max: 0 })
     const todayDate = new Date().toLocaleString("en-US", {
       timeZone: "America/Los_Angeles",
     });
     const momentDate = moment(todayDate, "MM/DD/YYYY, h:mm:ss A");
     const formattedDate = momentDate.toDate();
    const handleClick = (event) => {
        //setActive(event.target.id);
        activetab(event.target.id);
        window?.localStorage?.setItem("activefiltertab", event.target.id);
        if (event.target.id == "realtime") {
            //console.log(time, 'time');
            setStartDate(formattedDate);
            setSliderFilterData({ ...InputRangeValue, active: 'realtime' })
        }
    }

    useState(() => {
        //setActive("realtime");
        //swiftMange();
        // setTimeout(()=>{
        //     setStartDate(time)
        // },3000)
    }, [])

    const datepickerHandler = (date) => {
       
        setStartDate(date);
        activetab('datepicker');
        setSliderFilterData({ ...InputRangeValue, date, active: 'datepicker' })
    } 
 

    return (
      <div className="d-flex timeline">
        <div className="col flex-grow-0 me-2" id="pills-tabContent">
          <div className="col-md-12">
            <DatePicker
             placeholderText="Select date"
              showIcon
              maxDate={formattedDate}
              dateFormat="MM/dd/Y"
              className={active == "datepicker" ? "active" : ""}
              selected={startDate == "" ? time : startDate}
              onChange={(date) => datepickerHandler(date)}
            />
          </div>
          
        </div>
     
     
      </div>
    );
}

export default DateFilter;