import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal from "../../components/ActiveDailyManagementComponents/shared/InfoModal";
import FooterModal from "../../components/ActiveDailyManagementComponents/shared/footerModal";
import MissedMealBreakModal from "../../components/ActiveDailyManagementComponents/shared/MissedMealBreakModal";
import AgencySummaryModal from "../../components/ActiveDailyManagementComponents/shared/AgencySummaryModal";
import OvertimeModal from "../../components/ActiveDailyManagementComponents/shared/OvertimeModal";
import BiweeklyComments from "../../components/ActiveDailyManagementComponents/BiweeklyComments";

import ContentLoader from "react-content-loader";
// import Form from "react-bootstrap/Form";
import { Form, InputGroup } from "react-bootstrap";
import PageLayout from "../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Bi1 from "../../assets/img/bi1.png";
// import Bi2 from "../../assets/img/bi2.png";
// import Bi3 from "../../assets/img/bi3.png";
// import Bi4 from "../../assets/img/bi4.png";
// import SCHDULE from "../../assets/img/schduling.png";
import Bi1 from "../../assets/img/overtime/exceptions.svg";
import Bi2 from "../../assets/img/overtime/meal.svg";
import Bi3 from "../../assets/img/overtime/agency.svg";
import Bi4 from "../../assets/img/overtime/overtime.svg";
import SCHDULE from "../../assets/img/overtime/scheduling.svg";
import Arrow from "../../assets/img/down.png";
import Arrow1 from "../../assets/img/down-black.png";
import { get, isEmpty } from "lodash";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import BiweeklyChart from "../../components/ActiveChart/BiweeklyChart";
import { getTableRecordWithSorting } from "../../services";
import DescIcon from "../../assets/img/desc.png";
import AscIcon from "../../assets/img/asc.png";
import { ThemeContext } from "../../providers/ThemeProvider";
import { DOMAIN_URL } from "../../constants/compensate";
import PsLogo from "../../assets/img/pslogo.png";
import Button from "@atlaskit/button";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../Styled/DropdownStyle";
import {
  capitalizeAllLetters,
  formatNumberWithFraction,
} from "../../utils/helper";
import Select from "react-select";
import Submit1 from "../../assets/img/cht-b.png";
import Submit2 from "../../assets/img/cht-w.png";
import CPIC from "../../assets/img/chat-pic.png";
import SEND1 from "../../assets/img/send-b.png";
import SEND2 from "../../assets/img/send-w.png";
import AdmDetailModal from "./AdmSummary/AdmDetailModal";
import SHIFT_FORM from "../../constants/shiftForm";
import { decryptedFunction, dataEnCrypt } from "../../utils/helper";

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="15" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 120"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="120" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 30 }}>
    <ContentLoader
      viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
);

function ActiveManagement({ history }) {
  const dispatch = useDispatch();
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const location = useLocation();
  const { state } = location;
  const { department } = useParams();
  const [themeMode, setTheme] = useContext(ThemeContext);

  const [modalShowAdm, setModalShowAdm] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShowMeal] = React.useState(false);

  const [modalShow2, setModalShowAgency] = React.useState(false);

  const [modalShow3, setModalShowOvertime] = React.useState(false);

  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [graph1Data, setGraph1Data] = useState([]);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [graphMaxData, setGraphMaxData] = useState("");
  const [metaData, setMetaData] = useState([]);
  const momoDate = useSelector((state) => state.compensate.updateDate);
  const momoIndex = useSelector((state) => state.compensate.updatedIndex);
  const [scheduleData, setScheduleData] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [costCenterList, setCostCenterList] = useState([]);
  const [allCostCenter, setAllCostCenter] = useState([]);
  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);
  const [selectedOptionTwo, setSelectedOptionTwo] = useState(undefined);
  const [filterDateList, setFilterDateList] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [biweeklyData, setBiweeklyData] = useState([]);
  const [isActive1, setActive1] = useState(false);
  const [dailyData, setDailyData] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [dailyParameter, setDailyParameter] = useState({});  

  const getCostCenterList = async (ccn) => {

    try {

      const paramData = {
        businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/costCentersLit`,
        { payloadData: encryptData }
      );

      const data = decryptedFunction(response?.data?.responseData);

      setCostCenterList(
        data?.data?.costCenterResponse?.map((item) => {
          return {
            label: `${item.cost_center_number} - ${capitalizeAllLetters(
              item.cost_center_name
            )}`,
            value: item.cost_center_number,
          };
        })
      );

      setAllCostCenter(data?.data?.costCenterResponse);

      const matchingCostCenter = data.data?.costCenterResponse.filter(
        (val) => val.cost_center_slug === department
      )?.[0];

      if (!matchingCostCenter) {
           history(`/activedailymanagement/not-found`);
      }
      setDropdownValue({
        label: `${
          matchingCostCenter?.cost_center_number
        } - ${capitalizeAllLetters(matchingCostCenter?.cost_center_name)}`,
        value: matchingCostCenter?.cost_center_number,
      });
      return matchingCostCenter;
    } catch (err) {
      console.log(err);
    }
  };

  const getFilterDate = async () => {
    try {
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/payPeriodDates`
      );
      const data = decryptedFunction(response?.data?.responseData);
      setFilterDateList(
        data?.data?.payPeriodEndDates?.map((item) => {
          return {
            label: item.display_date,
            value: item.payload_date,
          };
        })
      );

      //  const matchingCostCenter = data?.data?.payPeriodEndDates[0];

         if (state?.urldate) {
          const matchingCostCenter = {
            display_date: state?.urldate?.label,
            payload_date: state?.urldate?.value,
          };
           setDropdownValueTwo({
             label: state?.urldate?.label,
             value: state?.urldate?.value,
           });
          return matchingCostCenter;
         } else {
          const matchingCostCenter = data?.data?.payPeriodEndDates[0];
           setDropdownValueTwo({
             label: matchingCostCenter?.display_date,
             value: matchingCostCenter?.payload_date,
           });
           return matchingCostCenter;
         }

    } catch (err) {
      console.log(err);
    }
  };

  const getTableData = async (eCost, eDate) => {
    setIsTableLoading(true);
    setIsGraphLoading(true);
    setIsLoading(true);
    try {
        const paramData = {
          cost_center_number: eCost,
          payperiod_end_date: eDate,
          businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
        };

      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklySummary/getData`,
        { payloadData: encryptData }
      );
       const data = decryptedFunction(response?.data?.responseData);
      setIsTableLoading(false);
      setTableData(data?.data?.tableData);
      setHeaderData(data?.data?.header);
      setGraphData(data?.data?.graphData);
      setBiweeklyData(data?.data);

      const tableData = data.data?.tableData;
      if (tableData && tableData.length > 0) {
        const sum = tableData.reduce(function (previousValue, currentValue) {
          return {
            hours: previousValue.hours + currentValue.hours,
            target_hours:
              previousValue.target_hours + currentValue.target_hours,
            hours_variance:
              previousValue.hours_variance + currentValue.hours_variance,
            uos: previousValue.uos + currentValue.uos,
            hpuos: previousValue.hpuos + currentValue.hpuos,
            worked_hours:
              previousValue.worked_hours + currentValue.worked_hours,
            paid_hours:
              previousValue.paid_hours + currentValue.paid_hours,
            target_paid_hours:
              previousValue.target_paid_hours + currentValue.target_paid_hours,
            paid_hours_variance:
              previousValue.paid_hours_variance + currentValue.paid_hours_variance
          };
        });

        setTotalvalue(sum);
      } else {
        setTotalvalue(null);
      }
      setIsGraphLoading(false);
      setIsLoading(false);
      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const getCostCenterNumber = async () => {
    setIsTableLoading(true);
    setIsGraphLoading(true);
    setIsLoading(true);
    const para = {
      slug: department,
      facility_id: 1,
    };
    const encryptData = dataEnCrypt(para);
    const resp = await axios.post(
      API_CALL_URL_PRIVATE + "/department/getCostCenterData",
      { payloadData: encryptData }
    );
    const data = decryptedFunction(resp?.data?.responseData);
    const ccn = data.data?.cost_center?.cost_center_number;
    const metaResponse = await getMetaData(ccn);
    const dropDataResponse = await fetchData(ccn);
    //fetchDetails();
  };

  const fetchData = async (ccn) => {
    try {
      const Costlist = await getCostCenterList(ccn);
      const Datelist = await getFilterDate();
      await getTableData(Costlist?.cost_center_number, 
        Datelist?.payload_date
        );
      setIsGraphLoading(false);
      setIsLoading(false);
      setIsTableLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


   const getBuisnessCostCenter = async () => {
     try {
       const paramData = {
         businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
       };
       const encryptData = dataEnCrypt(paramData);
       const response = await axios.post(
         `${API_CALL_URL_PRIVATE}/admGeneral/costCentersLit`,
         { payloadData: encryptData }
       );
       const data = decryptedFunction(response?.data?.responseData);
       setCostCenterList(
         data?.data?.costCenterResponse?.map((item) => {
           return {
             label: `${item.cost_center_number} - ${capitalizeAllLetters(
               item.cost_center_name
             )}`,
             value: item.cost_center_number,
           };
         })
       );

       setAllCostCenter(data?.data?.costCenterResponse);

       const matchingCostCenter = data.data?.costCenterResponse[0];
       setDropdownValue({
         label: `${
           matchingCostCenter?.cost_center_number
         } - ${capitalizeAllLetters(matchingCostCenter?.cost_center_name)}`,
         value: matchingCostCenter?.cost_center_number,
       });
        const url = `/activedailymanagement/adm-summary/${matchingCostCenter?.cost_center_slug}`;
        history(url);
       return matchingCostCenter;
     } catch (err) {
       console.log(err);
     }
   };


    const fetchBuissnessData = async () => {
      try {
        const Costlist = await getBuisnessCostCenter();
        const Datelist = await getFilterDate();
        await getTableData(
          Costlist?.cost_center_number,
          Datelist?.payload_date
        );
        setIsGraphLoading(false);
        setIsLoading(false);
        setIsTableLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

  

  useEffect(() => {
    getCostCenterNumber();
    getRefreshSchedule();
  }, []);

  // useEffect(() => {
  //   getCostCenterNumber();
  // }, [buisnessHeader]);

  const sortingHandle = (key, bindname = null) => {
    const dataToSort = bindname ? get(tableData, bindname, []) : tableData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");
     activityLog(SHIFT_FORM.ADM_SUMMARY_TABLE[key] ?? "", sortType);

    if (bindname) {
      setTableData({ ...tableData, [bindname]: sortdata });
    } else {
      setTableData(sortdata);
    }

    setsortName(key);
  };

  const getMetaData = async (ccn) => {
    try {
     const param = {
       application_id: 105,
       cost_center_number: ccn,
     };
     const encryptData = dataEnCrypt(param);
     const response = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
       payloadData: encryptData,
     });
     const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Active Daily Management";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  const toggleOpen = () => {
    setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
  };

  const toggleOpenTwo = () => {
    setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
  };

  useEffect(() => {}, [isDropdownOpen, dropdownValue]);

  const jumpFun = (e) => {
    let filVal = allCostCenter.filter((val) => val.cost_center_number === e);
    const url = `/activedailymanagement/adm-summary/${filVal[0]?.cost_center_slug}`;
    if (filVal[0]?.cost_center_slug) {
      history(url);
    } else {
      return "";
    }
  };

  const getRefreshSchedule = async (refreshType) => {

    try {
       const parData = {};
       if (refreshType) {
         parData.clicked = 1;
       }

       const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleClass1 = () => {
    setActive1(!isActive1);
  };
  const options = [
    { value: "18-01-20024", label: "18-01-20024" },
    { value: "18-01-20024", label: "19-01-20024" },
    { value: "18-01-20024", label: "20-01-20024" },
  ];

  function capitalizeEachWord(str) {
    return str?.toLowerCase()?.replace(/(^|\s)\S/g, function (match) {
      return match?.toUpperCase();
    });
  }

  const displayScheduleValue = (value) => {
    if (value < 0 && value > -1) {
      return value?.toFixed(2);
    } else {
      return formatNumberWithFraction(value, 0);
    }
  };

   const admDailyDetail = async ( date, dateType) => {
     try {
       const paramData = {
         cost_center_number: dropdownValue?.value,
         when_posted: date,
         date_type: dateType ? dateType : null,
       };
       const encryptData = dataEnCrypt(paramData);
       const response = await axios.post(
         `${API_CALL_URL_PRIVATE}/biweeklySummary/dailyDetail`,
         { payloadData: encryptData }
       );
      const data = decryptedFunction(response?.data?.responseData);
       setDailyData(data.data);
       setDailyParameter({
         when_posted: date,
       });
     } catch (error) {
       console.log(error);
     }
   };

    useEffect(() => {
    if (mounted) {
      fetchBuissnessData();
    } else {
      setMounted(true);
    }
    }, [buisnessHeader]);

     const activityLog = async (table_column, sortType) => {
       try {
        const paramData = {
          activity_id: 47,
          page_type: "ADM Summary",
          application_id: 105,
          table_name: "ADM Summary",
          table_column: table_column,
          sort_order: sortType === "asc" ? "Ascending" : "Descending",
          businessUnit: isBuisnessUnitSet,
          payperiod_end_date: dropdownValueTwo?.value,
          cost_center_number: dropdownValue?.value,
        };

        const encryptData = dataEnCrypt(paramData);

         const { data } = await axios.post(
           `${API_CALL_URL_PRIVATE}/activity-log/create`,
           { payloadData: encryptData }
         );
       } catch (err) {
         console.log(err);
       }
     };

  return (
    <PageLayout>
      <div
        className="activedailymanagement-s "
        style={{ textTransform: "capitalize" }}
      >
        <Row className="gx-2 mt-0 align-items-center">
          <Col md={{ span: 1 }}>
            <Link
              to={`/activedailymanagement`}
              className="f-14 white-text-dark-mode"
              // onClick={() => {
              //     dispatch(setUpdateDate(""));
              //     dispatch(setUpdateIndex(""));
              // }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
          </Col>
          <Col md={{ span: 10 }}>
            <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
              <div>
                <h3 className="text-center color-black m-0">ADM Summary -</h3>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      {dropdownValue ? dropdownValue.label : ""}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpen}
                    onChange={(e) => {
                      toggleOpen();
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueTwo?.value);
                      jumpFun(e?.value);
                    }}
                    options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={dropdownValue}
                  />
                </CustomDropdown>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpentwo}
                  onClose={toggleOpenTwo}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenTwo}
                      isSelected={isDropdownOpentwo}
                    >
                      {dropdownValueTwo ? dropdownValueTwo.label : ""}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpentwo}
                    options={filterDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenTwo();
                      setDropdownValueTwo(e);
                      getTableData(dropdownValue?.value, e?.value);
                    }}
                    value={dropdownValueTwo}
                  />
                </CustomDropdown>
              </div>
            </div>
          </Col>
          <Col md={12} className="text-end mobile-center mobile-my-2"></Col>

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>
        <Row className="header-bar gx-2  mt-3 align-items-center primary-font 11 active-header">
          <Col>
            <div class="position-relative  h-100 box-h col-bg">
              <Link
                to={`/activedailymanagement/exceptionsummary/${department}`}
                state={{
                  urlslug: department,
                  urldate: {
                    label: dropdownValueTwo?.label,
                    value: dropdownValueTwo?.value,
                  },
                }}
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="i d-flex align-items-center">
                    <span class="f-25 lh-1 d-flex align-items-center">
                      <img
                        src={Bi1}
                        alt=""
                        class="img-fluid"
                        style={{ marginRight: "5px" }}
                      />
                      Exceptions
                    </span>
                  </div>
                  {isLoading ? (
                    <LinkLoader themeMode={themeMode} />
                  ) : (
                    <div class="ps-2">
                      <div class="pe-0 primary-font">
                        <span class="big-num  f-25 color-black fw-600 color-green">
                          {headerData?.exceptions?.toLocaleString("en-US", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                        &nbsp;{" "}
                      </div>
                    </div>
                  )}
                </div>
              </Link>
            </div>
          </Col>
          <Col>
            <div class="position-relative  h-100 box-h col-bg">
              <Link
                to={`/activedailymanagement/schedule-details/${department}`}
                state={{
                  urlslug: department,
                  urldate: {
                    label: dropdownValueTwo?.label,
                    value: dropdownValueTwo?.value,
                  },
                }}
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="i d-flex align-items-center">
                    <span class="f-25  lh-1  d-flex align-items-center">
                      <img
                        src={SCHDULE}
                        alt=""
                        class="img-fluid"
                        style={{ marginRight: "5px" }}
                      />
                      Schedule
                    </span>
                  </div>
                  {isLoading ? (
                    <LinkLoader themeMode={themeMode} />
                  ) : (
                    <div class="ps-2">
                      <div class="pe-0 primary-font">
                        <span class="big-num  f-25 color-black fw-600 color-green">
                          {displayScheduleValue(headerData?.schedules)}
                          {/* {" - "} */}
                        </span>
                        &nbsp;{" "}
                      </div>
                    </div>
                  )}
                </div>
              </Link>
            </div>
          </Col>
          <Col>
            <div class="position-relative  h-100 box-h col-bg cursor-hand">
              <Link
                to={`/activedailymanagement/overtime-summary/overtimedetails/${department}`}
                state={{
                  urlslug: department,
                  urldate: {
                    label: dropdownValueTwo?.label,
                    value: dropdownValueTwo?.value,
                  },
                }}
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="i d-flex align-items-center">
                    <span class="f-25  lh-1  d-flex align-items-center">
                      <img
                        src={Bi4}
                        alt=""
                        class="img-fluid"
                        style={{ marginRight: "5px" }}
                      />
                      Overtime
                    </span>
                  </div>
                  {isLoading ? (
                    <LinkLoader themeMode={themeMode} />
                  ) : (
                    <div class="ps-2">
                      <div class="pe-0 primary-font">
                        <span class="big-num  f-25 color-black fw-600 color-green">
                          {headerData?.overtimes?.toLocaleString("en-US", {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                          })}
                        </span>
                        &nbsp;{" "}
                      </div>
                    </div>
                  )}
                </div>
              </Link>
            </div>
          </Col>
          <Col>
            <div
              class="position-relative  h-100 box-h col-bg "
              //  onClick={() => setModalShowMeal(true)}
            >
              <Link
                to={`/activedailymanagement/missedmealbreak-details/${department}`}
                state={{
                  urlslug: department,
                  urldate: {
                    label: dropdownValueTwo?.label,
                    value: dropdownValueTwo?.value,
                  },
                }}
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="i d-flex align-items-center">
                    <span class="f-25 lh-1  d-flex align-items-center">
                      <img
                        src={Bi2}
                        alt=""
                        class="img-fluid"
                        style={{ marginRight: "5px" }}
                      />
                      Missed Meal Break
                    </span>
                  </div>
                  {isLoading ? (
                    <LinkLoader themeMode={themeMode} />
                  ) : (
                    <div class="ps-2">
                      <div class="pe-0 primary-font">
                        <span class="big-num  f-25 color-black fw-600 color-green">
                          {headerData?.missed_meal_breaks?.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }
                          )}
                        </span>
                        &nbsp;{" "}
                      </div>
                    </div>
                  )}
                </div>
              </Link>
            </div>
          </Col>
          <MissedMealBreakModal
            show={modalShow1}
            onHide={() => setModalShowMeal(false)}
          />

          <Col>
            <div
              class="position-relative  h-100 box-h col-bg"
              //  onClick={() => setModalShowAgency(true)}
            >
              <Link
                to={`/activedailymanagement/agency-details/${department}`}
                state={{
                  urlslug: department,
                  urldate: {
                    label: dropdownValueTwo?.label,
                    value: dropdownValueTwo?.value,
                  },
                }}
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="i d-flex align-items-center">
                    <span class="f-25  lh-1  d-flex align-items-center">
                      <img
                        src={Bi3}
                        alt=""
                        class="img-fluid"
                        style={{ marginRight: "5px" }}
                      />
                      Agency
                    </span>
                  </div>
                  {isLoading ? (
                    <LinkLoader themeMode={themeMode} />
                  ) : (
                    <div class="ps-2">
                      <div class="pe-0 primary-font">
                        <span class="big-num  f-25 color-black fw-600 color-green">
                          {headerData?.agencies?.toLocaleString("en-US", {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                          })}
                        </span>
                        &nbsp;{" "}
                      </div>
                    </div>
                  )}
                </div>
              </Link>
            </div>
          </Col>
          <AgencySummaryModal
            show={modalShow2}
            onHide={() => setModalShowAgency(false)}
          />
        </Row>

        <Row className="gx-2">
          <Col md={6} className="mt-2 h-660">
            <div className="bg-white p-3 h-100">
              <div class="text-center mb-2">
                <div class="box-heading text-center">ADM Summary</div>
              </div>
              <div className="overlfow-auto">
                <Table className="table activity-tabel mb-0 ind-table tb2  table-hover exceptions-table scroll-table min-w-1100 biwekkly-table">
                  <thead>
                    <tr className="vertical-align-middle">
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("date_show")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Date and DOW</div>
                          <div className="ms-2">
                            {sortType === "asc" && sortName === "date_show" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      {biweeklyData?.fixed_or_variable === "variable" && (
                        <th
                          className="text-center cursor-pointer"
                          onClick={() => sortingHandle("uos")}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div>UOS</div>
                            <div className="ms-2">
                              {sortType === "asc" && sortName === "uos" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                      )}
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("worked_hours")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Worked Hours</div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "worked_hours" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      {biweeklyData?.fixed_or_variable === "fixed" && (
                        <th
                          className="text-center cursor-pointer"
                          onClick={() => sortingHandle("paid_hours")}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div>Paid Hours</div>
                            <div className="ms-2">
                              {sortType === "asc" &&
                              sortName === "paid_hours" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                      )}
                      {biweeklyData?.fixed_or_variable === "fixed" && (
                        <th
                          className="text-center cursor-pointer"
                          onClick={() => sortingHandle("target_paid_hours")}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div>Target Paid Hours</div>
                            <div className="ms-2">
                              {sortType === "asc" &&
                              sortName === "target_paid_hours" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                      )}
                      {biweeklyData?.fixed_or_variable === "fixed" && (
                        <th
                          className="text-center cursor-pointer"
                          onClick={() => sortingHandle("paid_hours_variance")}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div>Variance</div>
                            <div className="ms-2">
                              {sortType === "asc" &&
                              sortName === "paid_hours_variance" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                      )}
                      {biweeklyData?.fixed_or_variable === "variable" && (
                        <th
                          className="text-center cursor-pointer"
                          onClick={() => sortingHandle("target_hours")}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div>Target Hours</div>
                            <div className="ms-2">
                              {sortType === "asc" &&
                              sortName === "target_hours" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                      )}

                      {biweeklyData?.fixed_or_variable === "variable" && (
                        <th
                          className="text-center cursor-pointer"
                          onClick={() => sortingHandle("hpuos")}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div>HPUOS</div>
                            <div className="ms-2">
                              {sortType === "asc" && sortName === "hpuos" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                      )}
                      {biweeklyData?.fixed_or_variable === "variable" && (
                        <th
                          className="text-center cursor-pointer"
                          onClick={() => sortingHandle("hours_variance")}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div>Variance</div>
                            <div className="ms-2">
                              {sortType === "asc" &&
                              sortName === "hours_variance" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                      )}

                      <th width={20} className="w-20"></th>
                      {/* <th
                                            className="text-center cursor-pointer"
                                            onClick={() => sortingHandle('hours_variance')}
                                        >
                                            <div className="d-flex justify-content-center align-items-center">
                                                <div>Comment</div>
                                                <div className="ms-2">
                                                    {sortType === "asc" && sortName === "hours_variance" ? <img src={AscIcon} alt="" /> : <img src={DescIcon} alt="" />}
                                                </div>
                                            </div>
                                        </th> */}
                      {/* <th
                                            className="text-center cursor-pointer"
                                            onClick={() => sortingHandle('hours_variance')}
                                        >
                                            <div className="d-flex justify-content-center align-items-center">
                                                <div>Submitted By</div>
                                                <div className="ms-2">
                                                    {sortType === "asc" && sortName === "hours_variance" ? <img src={AscIcon} alt="" /> : <img src={DescIcon} alt="" />}
                                                </div>
                                            </div>
                                        </th> */}
                    </tr>
                  </thead>
                  <tbody className="crossi h-472">
                    {!isTableLoading ? (
                      tableData?.length > 0 ? (
                        tableData?.map((val, ind) => {
                          return (
                            <tr
                              key={ind}
                              style={{ cursor: "pointer" }}
                              className={
                                selectedRow === val?._id
                                  ? "selected-active-row"
                                  : ""
                              }
                              onClick={() => {
                                admDailyDetail(val?.date);
                                setModalShowAdm(true);
                              }}
                            >
                              <td className="text-center">{val?.date_show}</td>
                              {biweeklyData?.fixed_or_variable ===
                                "variable" && (
                                <td className="text-center">
                                  {val?.uos?.toLocaleString("en-US", {
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1,
                                  })}
                                </td>
                              )}
                              <td className="text-center">
                                {val?.worked_hours?.toLocaleString("en-US", {
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 1,
                                })}
                              </td>
                              {biweeklyData?.fixed_or_variable === "fixed" && (
                                <td className="text-center">
                                  {val?.paid_hours?.toLocaleString("en-US", {
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1,
                                  })}
                                </td>
                              )}
                              {biweeklyData?.fixed_or_variable === "fixed" && (
                                <td className="text-center">
                                  {val?.target_paid_hours?.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 1,
                                      maximumFractionDigits: 1,
                                    }
                                  )}
                                </td>
                              )}
                              {biweeklyData?.fixed_or_variable === "fixed" && (
                                <td className="text-center">
                                  {val?.paid_hours_variance?.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 1,
                                      maximumFractionDigits: 1,
                                    }
                                  )}
                                </td>
                              )}
                              {biweeklyData?.fixed_or_variable ===
                                "variable" && (
                                <td className="text-center">
                                  {val?.target_hours?.toLocaleString("en-US", {
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1,
                                  })}
                                </td>
                              )}

                              {biweeklyData?.fixed_or_variable ===
                                "variable" && (
                                <td className="text-center">
                                  {val?.hpuos?.toLocaleString("en-US", {
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                  })}
                                </td>
                              )}
                              {biweeklyData?.fixed_or_variable ===
                                "variable" && (
                                <td className="text-center">
                                  {new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1,
                                  }).format(get(val, "hours_variance", 0))}
                                </td>
                              )}

                              <td width={20} className="w-20">
                                {/* <img src={themeMode.theme === "DARK" ? Submit2 : Submit1}
                                                        className="cht-icon" onClick={toggleClass1}/> */}
                                {val?.comment_count > 0 ? (
                                  <Link
                                    to={`/activedailymanagement/adm-summary/comments`}
                                    state={{
                                      slug: dropdownValue,
                                      periodDate: val?.date,
                                    }}
                                  >
                                    {" "}
                                    <img
                                      alt=""
                                      src={
                                        themeMode.theme === "DARK"
                                          ? Submit2
                                          : Submit1
                                      }
                                      className="cht-icon"
                                    />
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </td>

                              {/* <td className="text-center small">2sick calls. Needed Agency.</td>
                                                        <td className="text-center small">Chase Cushman</td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "200px" }}
                        >
                          <p className="text-secondary display-6">
                            No Data Found
                          </p>
                        </div>
                      )
                    ) : (
                      <div>
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                      </div>
                    )}
                  </tbody>
                  <tbody className="ftr">
                    {isTableLoading ? (
                      <div>
                        <TableLoader themeMode={themeMode} />
                      </div>
                    ) : !isEmpty(totalvalue) ? (
                      <tr
                        className="bt"
                        onClick={() => {
                          admDailyDetail(
                            dropdownValueTwo?.value,
                            "payperiod_end_date"
                          );
                          setModalShowAdm(true);
                        }}
                      >
                        <td className="text-center">
                          <strong>Total</strong>
                        </td>
                        {biweeklyData?.fixed_or_variable === "variable" && (
                          <td className="text-center">
                            <strong>
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                              }).format(get(totalvalue, "uos", 0))}
                            </strong>
                          </td>
                        )}
                        <td className="text-center">
                          <strong>
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            }).format(get(totalvalue, "worked_hours", 0))}
                          </strong>
                        </td>
                        {biweeklyData?.fixed_or_variable === "fixed" && (
                          <td className="text-center">
                            <strong>
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                              }).format(get(totalvalue, "paid_hours", 0))}
                            </strong>
                          </td>
                        )}
                        {biweeklyData?.fixed_or_variable === "fixed" && (
                          <td className="text-center">
                            <strong>
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                              }).format(
                                get(totalvalue, "target_paid_hours", 0)
                              )}
                            </strong>
                          </td>
                        )}
                        {biweeklyData?.fixed_or_variable === "fixed" && (
                          <td className="text-center">
                            <strong>
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                              }).format(
                                get(totalvalue, "paid_hours_variance", 0)
                              )}
                            </strong>
                          </td>
                        )}
                        {biweeklyData?.fixed_or_variable === "variable" && (
                          <td className="text-center">
                            <strong>
                              {totalvalue?.target_hours?.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 1,
                                }
                              )}
                            </strong>
                          </td>
                        )}
                        {biweeklyData?.fixed_or_variable === "variable" && (
                          <td className="text-center">
                            <strong>
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              }).format(get(biweeklyData, "totalHpuos", 0))}
                            </strong>
                          </td>
                        )}
                        {biweeklyData?.fixed_or_variable === "variable" && (
                          <td className="text-center">
                            <strong>
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                              }).format(get(totalvalue, "hours_variance", 0))}
                            </strong>
                          </td>
                        )}

                        <td width={20} className="w-20"></td>
                        {/* <td className="text-center">

                                            </td>
                                            <td className="text-center">

                                            </td> */}
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
          <Col md={6} className="mt-2">
            <div className="bg-white border-bottom-5 h-250x p-3">
              <div class="box-heading text-center mb-2">
                Cost Center Details
              </div>
              <Row className="gx-2">
                <Col md="12">
                  {/* <Row>
                            <Col md="6" className="text-right">UOS Description :</Col>
                            <Col md="6" className="color-yellow">UOS description for the department</Col>
                            </Row> */}
                  <Row className="gx-2 px-14">
                    <Col md="5" className="wxt-40">
                      <Col md="12" className="mb-1">
                        <span>UOS Description : </span>
                        <span className=" tx">
                          {biweeklyData?.uos_description}
                        </span>
                      </Col>
                      <Col md="12" className="mb-1">
                        <span>Fixed or Variable : </span>
                        <span className="tx">
                          {biweeklyData?.fixed_or_variable}
                        </span>
                      </Col>
                    </Col>
                    <Col md="4" className="wxt-30">
                      <Col md="12" className="mb-1">
                        <span>Target Type : </span>
                        <span className=" txx">
                          {biweeklyData?.target_type}
                        </span>
                      </Col>
                      <Col md="12" className="mb-1">
                        <span>Director : </span>
                        <span className="txx">
                          {capitalizeEachWord(biweeklyData?.director)}
                        </span>
                      </Col>
                    </Col>
                    <Col md="3" className="wxt-30">
                      <Col md="12" className="mb-1">
                        <span>Manager : </span>
                        <span className="txx">
                          {capitalizeEachWord(biweeklyData?.manager)}
                        </span>
                      </Col>
                      <Col md="12" className="mb-1">
                        <span>Target : </span>
                        <span className="tx">
                          {biweeklyData?.fixed_or_variable === "fixed"
                            ? formatNumberWithFraction(biweeklyData?.target, 1)
                            : formatNumberWithFraction(biweeklyData?.target, 3)}
                        </span>
                      </Col>
                    </Col>
                  </Row>
                  {biweeklyData?.fixed_or_variable === "variable" && (
                    <Row className="m-0 mt-3 gx-2">
                      <Col sm={{ span: 4 }}>
                        <div className="border p-2 text-center">
                          <h4 className="text-center justify-content-center mb-0 fs-16">
                            UOS
                          </h4>
                          <h4 className="text-center justify-content-center mb-0 fw-light f-22">
                            {totalvalue?.uos || totalvalue?.uos === 0
                              ? formatNumberWithFraction(totalvalue?.uos, 1)
                              : " - "}
                            {/* {"297"} */}
                          </h4>
                          <div className="f-14">&nbsp;</div>
                        </div>
                      </Col>
                      <Col sm={{ span: 4 }}>
                        <div className="border p-2 text-center">
                          <h4 className="text-center justify-content-center mb-0 fs-16">
                            WORKED HOURS
                          </h4>
                          <h4
                            className={`text-center justify-content-center f-22 mb-0
                            ${
                              totalvalue?.worked_hours <=
                              totalvalue?.target_hours
                                ? "text-success"
                                : "text-danger "
                            }`}
                          >
                            {totalvalue?.worked_hours ||
                            totalvalue?.worked_hours === 0
                              ? formatNumberWithFraction(
                                  totalvalue?.worked_hours,
                                  1
                                )
                              : " - "}
                          </h4>
                          <div className="f-14">
                            Target:{" "}
                            {formatNumberWithFraction(
                              totalvalue?.target_hours,
                              1
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col sm={{ span: 4 }}>
                        <div className="border p-2 text-center">
                          <h4 className="text-center justify-content-center mb-0 fs-16">
                            HPUOS
                          </h4>
                          <h4
                            className={`text-center justify-content-center mb-0  f-22
                            ${
                              totalvalue?.hpuos <= biweeklyData?.target
                                ? "text-success"
                                : "text-danger "
                            }`}
                            // className="className={`text-center justify-content-center  f-16x"
                          >
                            {totalvalue.hpuos || totalvalue?.hpuos === 0
                              ? formatNumberWithFraction(totalvalue?.hpuos, 3)
                              : " - "}
                          </h4>
                          <div className="f-14">
                            Target:{" "}
                            {formatNumberWithFraction(biweeklyData?.target, 3)}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {biweeklyData?.fixed_or_variable === "fixed" && (
                    <Row className="m-0 mt-3 gx-2">
                      <Col sm={{ span: 4 }}>
                        <div className="border p-2 text-center">
                          <h3 className="text-center justify-content-center mb-0 fs-16">
                            WORKED HOURS
                          </h3>
                          <h3 className="text-center justify-content-center mb-0 fw-light f-22">
                            {totalvalue?.worked_hours ||
                            totalvalue?.worked_hours === 0
                              ? formatNumberWithFraction(
                                  totalvalue?.worked_hours,
                                  1
                                )
                              : " - "}
                            {/* {"297"} */}
                          </h3>
                          <div className="f-14">&nbsp;</div>
                        </div>
                      </Col>
                      <Col sm={{ span: 4 }}>
                        <div className="border p-2 text-center">
                          <h4 className="text-center justify-content-center mb-0 fs-16">
                            PAID HOURS
                          </h4>
                          <h4
                            className={`text-center justify-content-center mb-0 f-22
                            ${
                              totalvalue?.paid_hours <=
                              totalvalue?.target_paid_hours
                                ? "text-success"
                                : "text-danger "
                            }`}
                            // className="text-center justify-content-center f-16x"
                          >
                            {totalvalue?.paid_hours ||
                            totalvalue?.paid_hours === 0
                              ? formatNumberWithFraction(
                                  totalvalue?.paid_hours,
                                  1
                                )
                              : " - "}
                          </h4>
                          <div className="f-14">
                            Target:{" "}
                            {formatNumberWithFraction(
                              totalvalue?.target_paid_hours,
                              1
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col sm={{ span: 4 }}>
                        <div className="border p-2 text-center">
                          <h4 className="text-center justify-content-center mb-0 fs-16">
                            PAID HOUR VARIANCE
                          </h4>
                          <h4
                            className={`text-center justify-content-center mb-0  f-22
                            ${
                              totalvalue?.paid_hours_variance <=
                              biweeklyData?.target
                                ? "text-success"
                                : "text-danger "
                            }`}
                            // className="className={`text-center justify-content-center  f-16x"
                          >
                            {totalvalue.paid_hours_variance ||
                            totalvalue?.paid_hours_variance === 0
                              ? formatNumberWithFraction(
                                  totalvalue?.paid_hours_variance,
                                  1
                                )
                              : " - "}
                          </h4>
                          <div className="f-14">
                            Target:{" "}
                            {formatNumberWithFraction(biweeklyData?.target, 1)}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
            <div className="bg-white h-full-200 pt-2 overflow-hidden">
              <div
                style={{ textTransform: "none" }}
                class="box-heading text-center"
              >
                {biweeklyData?.graphDataLabels?.heading}
              </div>
              {isGraphLoading ? (
                <GraphLoader themeMode={themeMode} />
              ) : (
                <BiweeklyChart
                  data={graphData}
                  apiData={biweeklyData}
                  maxData={graphMaxData}
                  // chartColor="#D7263D"
                  // chartName="Paid vs Target Paid Hours By Day"
                  // indexNum={momoIndex}
                  tooltip={biweeklyData?.graphDataLabels?.tooltip}
                />
              )}
            </div>
          </Col>
          <Col md={12} className="ft-text mt-2 mb-2 px-1 ft-text">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              <div className="col-12 text-end text-mobile-center bg-white">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  onClick={() => {
                    getRefreshSchedule(true);
                    setFooterShow(true);
                  }}
                >
                  {scheduleData?.exception?.currentFileMsg}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <AdmDetailModal
        show={modalShowAdm}
        onHide={() => {
          setModalShowAdm(false);
          // getCostCenterNumber();
          getTableData(dropdownValue?.value, dropdownValueTwo?.value);
        }}
        tableData={dailyData}
        overtimeDailyFun={admDailyDetail}
        overtimeDailyParameter={dailyParameter}
      />

      {/* ===================================================== */}

      <div id="mySidenav" className={isActive1 ? "sidemessagebar" : null}>
        <h5 className="text-center">
          Biweekly Summary - 70404 Transport communication CTR
        </h5>
        <div className="chat-date">
          {" "}
          <h5>
            <Select value={selectedOption} options={options} />
          </h5>
        </div>
        <div>
          <hr />
        </div>
        <div className="chat-area">
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
          <div className="chating-area">
            <div className="chat-perrson-img">
              <img src={CPIC} alt="" className="chat-pic" />
            </div>
            <div className="chat-details">
              <div className="d-flex justify-content-between">
                <div className="chat-person">Cyrus Benjamin</div>
                <div className="chat-dt">27 November 2023 at 11:24</div>
              </div>
              <div className="chat-message">
                When we implemented the small rings, we decided on a simplified
                view (without the 6 smaller boxes). If we are wanting to change
                that now, we should discuss internally before moving this into a
                sprint.
              </div>
            </div>
          </div>
        </div>
        <div className="comment-area">
          <div className="chat-g">
            <input type="text" placeholder="Type a message" />

            <Button type="submit">
              <img
                src={themeMode.theme === "DARK" ? SEND2 : SEND1}
                alt=""
                class="submit"
              />
            </Button>
          </div>
        </div>
        <a href="javascript:void(0)" class="closebtn" onClick={toggleClass1}>
          &times;
        </a>
      </div>

      {/* ===================================================== */}
    </PageLayout>
  );
}

export default ActiveManagement;
