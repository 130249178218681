import { useContext } from "react";
import Chart from "react-apexcharts";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "../../../providers/ThemeProvider";


import moment from "moment";

const MyLoader = ({ themeMode }) => (
  <ContentLoader viewBox="0 0 100 25"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="1" rx="2" ry="2" width="100" height="24" />
  </ContentLoader>
);

function BarChart({
  admitlinechartat,
  admitlinechartseries,
  isLoader,
  color,
  title,
  data,
  minData,
  maxMaxData,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);


  var options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      color: themeMode.theme === "DARK" ? "white" : "black",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming behavior
      },
      
    },
  
    colors: ["#6ca9d6"],
 
    stroke: {
      curve: "smooth",
      width: [2, 2]
    },
    markers: {
      size: 6,
      strokeColors: '#fff',
      hover: {
        size: 6,
        sizeOffset: 3,
      },
    },
    
   
    plotOptions: {
        bar: {
          columnWidth: '40%',
          barHeight: '40%',
          dataLabels: {
            position: "top", // top, center, bottom

          },
        },

      },
      dataLabels: {
        enabled: true,
        hideOverflowingLabels: true,
      
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
          padding: {
            top: 30,
          },
        },
      },
    xaxis: {
        categories: ["Oct 2022", "Jan 2023", "Apr 2023", "Jul 2023", "Oct 2023", "Jan 2024", "Apr 2024", "Aug 2024"],
      title: {
        show: false,
        
      },
      labels: {
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
      },
      tickPlacement: 'between',
    },
    yaxis: {
        show: true,
       // tickAmount: 5,
       labels: {
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
   
      },
        
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
       
      },
    legend: {
        labels: {
            colors: themeMode.theme === "DARK" ? "white" : "black",
            useSeriesColors: false
        },
      horizontalAlign: "center",
      position: "top",
      offsetY: 20,
      offsetX: 0
    },

    annotations: {
        yaxis: [
          {
            y: 2.3,
            strokeDashArray: 8,
            borderWidth: 1,
            borderColor: themeMode.theme === "DARK" ? "#6ca9d6" : "#6ca9d6",
          
            label: {
              borderColor: "#6ca9d6",
              textAnchor: "start",
              position: "left",
              style: {
                color: "#000",
                background: "#6ca9d6"
              },
              text: "Avg. Target Worked FTEs"
            }
          },
          
          
        ],
    },

  };
  
  return (
    <div>
      {!isLoader ? (
        <div className="bg-white pb-0 ps-2 pe-2">
          <div class="f-14 text-center mb-minus-10 font-w-500" style={{ color: "themeMode.theme === 'DARK' ? 'white' : 'black'," }}>
            {title}
          </div>
          <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "left", alignItems: "center" }}>
            
            <div style={{ width: "100%" }}>
              <Chart
                options={options}
                series={[
                    {
                      name: "Target Worked FTEs",
                      data:[1.2, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
                    },
                  
                  ]}
           
                type="bar"
              //  width={"100%"}
               // height={135}
               height={285}
              />
            </div>
          </div>
        </div>
      ) : (
          <MyLoader themeMode={themeMode} />
      )}
    </div>
  );
}

export default BarChart;
